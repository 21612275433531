import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate,useLocation  } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { useOrders } from "../../hooks/SettingContext";
import  ResetCartModal  from "./modals/ResetCartModal";
import  ConfirmDeleteItemsModal  from "./modals/ConfirmDeleteItemsModal";
import  DecrementModal  from "./modals/DecrementModal";
import NewHeader from '../HeaderComponent/NewHeader.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 

function CartComponent() {
   
  const [loading, setLoading] = useState(true);
  const [showItemDelModal, setShowItemDelModal] = useState(false);
  const { ordersCount, updateOrdersCount } = useOrders();
  const navigate = useNavigate();
  const [total_Price, setTotal_Price] = useState(0);
  const [total_Local_Price, setTotal_Local_Price] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const  [deleteCountry,setDeleteCountry] = useState([]);
  const [resetCardModal, setResetCardModal] = useState(false);
  const [decrementItem, setDecrementItem] = useState(false); 
  const [groupedItemsByCountry, setGroupedItemsByCountry] = useState({});
  const [totalsByCountry, setTotalsByCountry] = useState({});

  useEffect(() => {
    const loginUser = localStorage.getItem("userID");
    const cartDataFromLocalStorage = JSON.parse(localStorage.getItem("CartData")) || {};

    if (cartDataFromLocalStorage[loginUser] && cartDataFromLocalStorage[loginUser].length > 0) {
      const itemsFromLocalStorage = cartDataFromLocalStorage[loginUser];

      const itemsByCountry = itemsFromLocalStorage.reduce((acc, item) => {
        const country = item.attributes.country;
        if (!acc[country]) {
          acc[country] = [];
        }
        acc[country].push(item);
        return acc;
      }, {});
      const countryTotals = {};
      Object.keys(itemsByCountry).forEach((country) => {
        const updatedItems = itemsByCountry[country].map((item) => ({
          ...item,
          totalPrice: item.quantity * item.per_ticket_price,
        }));
        const totalPrice = updatedItems.reduce((sum, item) => sum + item.totalPrice, 0);
        const totalItems = updatedItems.reduce((sum, item) => sum + item.quantity, 0);

        countryTotals[country] = {
          totalPrice,
          totalItems,
        };

        itemsByCountry[country] = updatedItems;
      });
      setGroupedItemsByCountry(itemsByCountry);
      setTotalsByCountry(countryTotals);

      const countries = Object.keys(itemsByCountry);
      const countryCount = countries.length;
   
      if (countryCount === 1) {
        const singleCountry = countries[0];  
        const slugCountry = generateSlug(singleCountry);
        navigate(`/cart/${slugCountry}`);   
      }
    }
  }, []);

 

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);  
    }, 2000); 

    return () => clearTimeout(timer);  

    const loginUser = localStorage.getItem("userID");
    const itemsJSON = localStorage.getItem("CartData");

    let totalQuantity = 0;
  
    if (itemsJSON) {
      const items = JSON.parse(itemsJSON);
  
      // Filter items by loginUser
      if (items[loginUser]) {
        items[loginUser].forEach((item) => {
          totalQuantity += item.quantity;
        });
      }
    }
  
    localStorage.setItem("ordersCount", totalQuantity);
    updateOrdersCount(totalQuantity); 
    
  }, []);

   
    
  const handleDeleteItemConfirm = (country) => {
    setDeleteCountry(country);
    setShowItemDelModal(true);
  };
  

  const handleDeleteItem = () => {
    const country = deleteCountry; // Get the country to delete
    console.log(country);
    const loginUser = localStorage.getItem("userID");
    let cartDataFromLocalStorage = JSON.parse(localStorage.getItem("CartData")) || {};
  
    if (cartDataFromLocalStorage[loginUser] && cartDataFromLocalStorage[loginUser].length > 0) {
      // Filter out items for the specified country
      const updatedItems = cartDataFromLocalStorage[loginUser].filter(
        (cartItem) => cartItem.attributes.country !== country
      );
  
      // Update the local state with the updated items
      setGroupedItemsByCountry(
        updatedItems.reduce((acc, cartItem) => {
          const country = cartItem.attributes.country;
          if (!acc[country]) {
            acc[country] = [];
          }
          acc[country].push(cartItem);
          return acc;
        }, {})
      );
  
      // Recalculate totals for each country
      const updatedCountryTotals = updatedItems.reduce((acc, item) => {
        const country = item.attributes.country;
        if (!acc[country]) {
          acc[country] = { totalPrice: 0, totalItems: 0 };
        }
        acc[country].totalPrice += item.totalPrice;
        acc[country].totalItems += item.quantity;
        return acc;
      }, {});
  
      setTotalsByCountry(updatedCountryTotals);
  
      // Update total prices
      const newTotalPrice = updatedItems.reduce((total, currentItem) => total + currentItem.totalPrice, 0);
      const totalLocalPrice = updatedItems.reduce((total_Local_currency_price, currentItem) => total_Local_currency_price + currentItem.total_Local_currency_price, 0);
  
      setTotal_Price(newTotalPrice);
      setTotal_Local_Price(totalLocalPrice);
  
      // Update cartDataFromLocalStorage for loginUser
      cartDataFromLocalStorage[loginUser] = updatedItems;
  
      // Save the updated CartData back to localStorage
      localStorage.setItem("CartData", JSON.stringify(cartDataFromLocalStorage));
      localStorage.setItem("FakeOrder", JSON.stringify(cartDataFromLocalStorage));
      localStorage.setItem("orders", JSON.stringify(updatedItems));
  
      // Update 'ordersCount' in localStorage
      const totalQuantity = updatedItems.reduce(
        (accumulator, currentItem) => accumulator + currentItem.quantity,
        0
      );
      localStorage.setItem("ordersCount", totalQuantity);
      updateOrdersCount(totalQuantity);
  
      // Reset delete state and close modal
      setDeleteCountry(null);
      setShowItemDelModal(false);
    }
  }; 
  
  const generateSlug = (name) => {
    return name
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '') // Remove special characters
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-'); // Remove multiple hyphens
  };
  return (
    <>

      {/* <PaymentForm/> */}
      <Helmet>
      <link href="/New-css/bootstrap.min.css" rel="stylesheet" />
        <link href="/css/cart_items.css" rel="stylesheet" />
        <link href="/New-css/slick-theme.css" rel="stylesheet" />
        <link href="/New-css/slick.css" rel="stylesheet" />
      </Helmet>


      {loading ? (
        <section className="pt-lg-5 pt-md-5 pt-3 bg-silver2">
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen"   style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
    <NewHeader/>
    <div>
     
      {loading ? (
                <LoadingSpinner />
              ) : (
                <>
      <section className="pt-lg-5 pt-md-5 pt-3 bg-silver2">
        <div className="container">
          <div className="d-block w-100 py-2">
            <h3 className="mt-0 mb-2 pt-0 pb-1 text-black text-29 mob-text-25 poppins-700">
                Country Carts
            </h3>
            <h5 className="text-black text-15 d-flex align-items-center justify-content-start" >
              <Link to="/" style={{'color':'black'}}> <span> Home </span> </Link>
             
              <i className="fa fa-solid fa-circle text-6 text-muted ms-2 me-2" />
              <Link to="/cart-items" style={{'color':'black'}}> <span>  Country Carts </span> </Link>
              
               
            </h5>
          </div>
        </div>
      </section>
      <section className="py-5 bg-silver2">
      <div className="container">
      
        <div className=" justify-content-start  ">
        {Object.keys(groupedItemsByCountry).length > 0 ? (
  Object.keys(groupedItemsByCountry).map((country) => (
    <div className="card mb-4 shadow-sm" key={country}>
      <div className="card-header bg-light p-3">
        <div className="d-flex align-items-start">
          <img
            src={groupedItemsByCountry[country][0].attributes.flag}
            alt={`${country} Flag`}
            className="img-fluid rounded-circle"
            style={{ width: '40px', height: '40px' }}
          />
          <div className="ms-3">
            <h5 className="mb-0">{country}</h5>
            <div>
              {groupedItemsByCountry[country].map((item) => (
                <div key={item.id} className="text-muted small mb-1">
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          <button
            className="btn btn-outline-danger btn-sm"
            style={{
              position: 'absolute',
              right: '22px',
              top: '20px',
            }}
            onClick={() => handleDeleteItemConfirm(country)}
          >
            <i className="fas fa-trash trash"></i>
          </button>
        </div>
      </div>
      <div className="card-body bg-white p-3">
        <div className="d-flex justify-content-between">
          <Link className="text-black fw-medium" to="/">
            <svg
              className="mr-1 styqz"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="15"
              height="60"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
              ></path>
            </svg>
            Add More Items
          </Link>

          <Link
            className="btn btn-primary btn-sm"
            to={`/cart/${generateSlug(country)}`}
          >
            View Cart
          </Link>
        </div>
      </div>
    </div>
  ))
) : (
  <div className="text-center mt-5">
  <img src="https://i.imgur.com/dCdflKN.png" id="EmptyImage" alt="Empty Cart" />
  <h3>No items in the cart</h3>
  <p>Your cart is empty. Start adding tickets to your cart!</p>
  <Link className="btn btn-primary" to="/">
    Explore Tickets
  </Link>
</div>

)}


        </div>
        <div class="d-block w-100 ">
          <Link to="/" className="text-black border-0 bg-transparent text-14 poppins-600 px-0 py-2 d-flex align-items-center justify-content-start">
             <i className="fa fa-angle-left text-black text-15 me-2"></i>Continue Shopping
          </Link>
        </div>
      </div>
    </section>


      </> )}
    </div>
    <ToastContainer />
    {resetCardModal && <ResetCartModal setResetCardModal={setResetCardModal} isMobile={isMobile} handleResetCart={handleResetCart} />}
    {decrementItem && <DecrementModal setDecrementItem={setDecrementItem} isMobile={isMobile}  />}
    {showItemDelModal && <ConfirmDeleteItemsModal showItemDelModal={showItemDelModal} setShowItemDelModal={setShowItemDelModal} isMobile={isMobile} handleDeleteItem={handleDeleteItem} />}
    <FooterComponent/> 
    </>
      )}
    </>

  );
}

export default CartComponent;
