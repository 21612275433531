import React, { useState, useEffect } from 'react';

function CountriesList({ totalRecords, countries, setSelectedCountry, setSelectedCountryValues, selectedCountryValues }) {
    
    const handleCountryChange = (event, countryValue) => {
        if (countryValue === 0) {
            if (event.target.checked) {
                setSelectedCountryValues([0]);
            } else {
                setSelectedCountryValues([]);
            }
        } else {
            if (event.target.checked) {
                setSelectedCountryValues((prevSelected) => {
                    const newSelection = prevSelected.filter(value => value !== 0);
                    return [...newSelection, countryValue];
                });
            } else {
                setSelectedCountryValues((prevSelected) =>
                    prevSelected.filter(value => value !== countryValue)
                );
            }
        }
    };

    useEffect(() => {
        if (selectedCountryValues.length === 0) {
            setSelectedCountryValues([0]);
        } else {
            setSelectedCountry(selectedCountryValues);
        }
    }, [selectedCountryValues, setSelectedCountry, setSelectedCountryValues]);

    return (
        <div className="d-block w-100 mb-4">
            <div className="d-block w-100 mb-3">
                <h4 className="text-black text-18 m-0 poppins-600">Countries</h4>
            </div>
            <div className="d-block w-100 bg-white px-3 py-3 rounded-2 shadow-normal customBorder">
                <div className="d-block w-100 mb-2">
                    <h4 className="text-black poppins-600 mt-0 mb-1 text-15">Event Countries</h4>
                </div>
                <div className="d-block w-100">
                {countries.slice().reverse().map(country => (
                        <div key={country.value} className="checkbox-style2 d-flex w-100 align-items-center justify-content-between mb-1">
                            <label className="text-black text-14 me-2 w-100" htmlFor={`country-${country.value}`}>
                                {country.value === 0 ? (
                                    <i className="fa fa-thin fa-globe ms-2" style={{ fontSize: '17px' }}></i>
                                ) : (
                                    country.country_flag && <img src={country.country_flag}  alt={`${country.label} flag`} className="ml-2 flg" style={{ width: '20px', height: '15px' }} />
                                )}
                                <span className='ms-1'>{country.label}</span>
                                <b className="poppins-600 text-14 text-muted ml-1">
                                        ({country.event_count})
                                </b>
                            </label> 
                            <input
                                className="m-0"
                                type="checkbox"
                                id={`country-${country.value}`}
                                name={`country-${country.value}`}
                                checked={selectedCountryValues.includes(country.value)}
                                onChange={(e) => handleCountryChange(e, country.value)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CountriesList;
