import React, { useEffect, useState } from "react";
import { Link,useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import NewHeader from '../HeaderComponent/NewHeader.js';
import { useLocation } from 'react-router-dom';
import { useOrders } from "../../hooks/SettingContext";
function ThankYouComponent() {
  
  const navigate = useNavigate();
  const { ordersCount, updateOrdersCount } = useOrders();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('order_id');
  const country = queryParams.get('country');
 
  useEffect(() => {
      // const loginUser = localStorage.getItem("userID");
      // const cartData = JSON.parse(localStorage.getItem("CartData")) || {};
      // delete cartData[loginUser];
      // localStorage.setItem("CartData", JSON.stringify(cartData));

      const loginUser = localStorage.getItem("userID");
      let cartDataFromLocalStorage = JSON.parse(localStorage.getItem("CartData")) || {};

      if (cartDataFromLocalStorage[loginUser] && cartDataFromLocalStorage[loginUser].length > 0) {
     
        const remainingCartItems = cartDataFromLocalStorage[loginUser].filter(
          (cartItem) => cartItem.attributes.country !== country  
      );
 
      if (remainingCartItems.length === 0) {
        delete cartDataFromLocalStorage[loginUser];   
      } else {
          cartDataFromLocalStorage[loginUser] = remainingCartItems;   
      }
    }
      localStorage.setItem("CartData", JSON.stringify(cartDataFromLocalStorage));
      const itemsJSON = localStorage.getItem("CartData");
  
      let totalQuantity = 0;
    
      if (itemsJSON) {
        const items = JSON.parse(itemsJSON);
    
        // Filter items by loginUser
        if (items[loginUser]) {
          items[loginUser].forEach((item) => {
            totalQuantity += item.quantity;
          });
        }
      }
    
      localStorage.setItem("ordersCount", totalQuantity);
      updateOrdersCount(totalQuantity); 
      localStorage.removeItem("paymentTotal");
      localStorage.removeItem("paymentOrder");
      localStorage.removeItem("items");
      localStorage.removeItem("items");
      localStorage.removeItem("discountValue");
      localStorage.removeItem("coupon_Id");
      localStorage.removeItem("items");
      localStorage.removeItem("orders");
      localStorage.removeItem("ticketQuantities");
      localStorage.removeItem("eventName");
      localStorage.removeItem("totalPrice");
      localStorage.removeItem("total_Price");
  }, []);


 
  const handleBackPage = () => {
    localStorage.removeItem('discountAmount');
    localStorage.removeItem('couponId');
    navigate('/user/my-tickets');
  }
  const handleContinuePage = () => {
    localStorage.removeItem('discountAmount');
    localStorage.removeItem('couponId');
    navigate('/');
  }
  return (
    <div>
      <Helmet>
      <link href="/New-css/bootstrap.min.css" rel="stylesheet" />
        <link href="/New-css/animate.css" rel="stylesheet" />
        <link href="/New-css/custom.css" rel="stylesheet" />
        <link href="/New-css/slick-theme.css" rel="stylesheet" />
        <link href="/New-css/slick.css" rel="stylesheet" />
      </Helmet>
      <NewHeader/>
      <section className="py-5 bg-silver2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6 col-sm-12 col-12 ps-3 pe-3">
              <div className="d-block w-100 text-center mb-4">
                <h3 className="text-black text-30 poppins-700 mob-text-20"> Thank You! </h3>
              </div>
              <div className="d-block w-100 text-center mb-4">
                <img className="custom-image2" src="/images/thankyou-graphics.png" style={{objectFit:'contain'}} />
              </div>
              <div className="d-block w-100 text-center">
                <h5 className="text-black poppins-500 text-15 mb-3"> Thanks for placing order : </h5>
                <h3 className="text-black poppins-700 text-19 mb-3 mob-text-17">
                  Your order is now confirmed
                </h3>
                <h5 className="text-black poppins-500 text-15 mb-2 mt-0">Order id: #{paramValue} </h5> 
                <h5 className="text-black poppins-500 text-15 mb-3 mt-0">
                  If you have any question or queries then fell to get in contact us.
                </h5>
                <h4 className="text-black poppins-700 text-16 mb-3 mt-0"> All the best, </h4>
              </div>
              <div className="d-block w-100 mt-4 mb-4 pt-2 pb-2">
                <span className="line-1 d-block w-100"> </span>
              </div>
              <div className="d-flex align-items-center justify-content-between flex-wrap" style={{width:'100%'}}>
                <button className="custom-btn2 btn-width1 mb-lg-0 mb-md-0 mb-sm-0 mb-3" id="thannkyougocontine" onClick={handleContinuePage}>
                  Continue Shopping
                </button>
                <button className="custom-btn3 btn-width1" id="thannkyougoback" onClick={handleBackPage}>
                My Tickets
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
}

export default ThankYouComponent;
