import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate,useLocation  } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { useOrders } from "../../hooks/SettingContext";
import NewHeader from '../HeaderComponent/NewHeader.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';
 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaymentForm from './PaymentForm';

  
function PaymentComponent() {
 
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('call');
  const {id} = useParams();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);  
    }, 2000); 

    return () => clearTimeout(timer);  
  }, []);

  return ( 
    <>

      {/* <PaymentForm/> */}
      <Helmet>
        <link href="/payment/bootstrap.min.css" rel="stylesheet" /> 
        <link href="/payment/style.css" rel="stylesheet" />
      </Helmet> 


      {loading ? (
        <section className="pt-lg-5 pt-md-5 pt-3 bg-silver2">
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen"   style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
   

    <section className="">   
        <div className="container-fluid">
          <div className="text-center info-mx">
            <img className="w-25" style={{marginTop: '33px', marginBottom: '33px'}} src="/payment/images/white_kanoo_logo.png" alt="Kanoo" />
            <h4 className="header mb-0">PAY WITH KANOO</h4>
            <div className="bg-white pt-4" style={{border: '1px solid #ECECEC', borderRadius: '0px 0px 12px 12px'}}> 
              <h6 className="Pay pt-4 pb-4">Pay with Credit Card</h6>
              <div className="row pe-5">
                <div className="col-xl-6 text-start pt-2">
                  <h6 className="PAYMENT ps-sm-5 ps-4">PAYMENT INFORMATION</h6>
                </div>
                <div className="col-xl-6 text-xl-end text-start ps-xl-0 ps-5 pt-xl-0 pt-3">
                  <img className="visa" src="/payment//images/visa.png" alt="" />
                  <img className="visa" src="/payment//images/mastercard.png" alt="" />
                  <img className="visa" src="/payment//images/americanExpress.png" alt="" />
                  <img className="visa" src="/payment//images/discover.jpeg" alt="" />
                </div>
              </div>
               <PaymentForm setActiveTab={setActiveTab} activeTab={activeTab} />
            </div>
            <div className="row pt-2 pb-4">
              <div className="col-3 text-start">
                <h6 className="Kanoo pt-4">Kanoo © 2024</h6>
              </div>
              <div className="col-9 text-end">
                <h6 className="Kanoo pt-4">Terms of Service | Privacy Policy</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
     
    <ToastContainer />
    
    </>
      )} 
    </>

  );
}

export default PaymentComponent;
