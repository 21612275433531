// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.text-red{
  color: red !important;
}

.bg-color-101828 {
  background: #101828 !important;
}
.rounded-6 {
  border-radius: 0.5rem !important;
}
.subscribe-footer-form {
  max-width: 450px;
}
.border-color-silver {
  border-color: silver !important;
}
.list-style-none {
  list-style: none;
}
.text-color-e7e7e7 {
  color: #e7e7e7 !important;
}
.emailinput::placeholder {
  color: white;
}
.text-start{
  text-align: left;
}
@media screen and (max-width: 767px) and (min-width: 320px) {
  .onMOb{
      width: 90%;
      transform: none !important;
      top: auto !important;
      margin: 20px auto !important;
  }
}  

.text-back{
 color: #212B36 !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,8BAA8B;AAChC;AACA;EACE,gCAAgC;AAClC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,+BAA+B;AACjC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;AACA;EACE;MACI,UAAU;MACV,0BAA0B;MAC1B,oBAAoB;MACpB,4BAA4B;EAChC;AACF;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n.text-red{\n  color: red !important;\n}\n\n.bg-color-101828 {\n  background: #101828 !important;\n}\n.rounded-6 {\n  border-radius: 0.5rem !important;\n}\n.subscribe-footer-form {\n  max-width: 450px;\n}\n.border-color-silver {\n  border-color: silver !important;\n}\n.list-style-none {\n  list-style: none;\n}\n.text-color-e7e7e7 {\n  color: #e7e7e7 !important;\n}\n.emailinput::placeholder {\n  color: white;\n}\n.text-start{\n  text-align: left;\n}\n@media screen and (max-width: 767px) and (min-width: 320px) {\n  .onMOb{\n      width: 90%;\n      transform: none !important;\n      top: auto !important;\n      margin: 20px auto !important;\n  }\n}  \n\n.text-back{\n color: #212B36 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
