import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate,useLocation  } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { useOrders } from "../../hooks/SettingContext";
import  ResetCartModal  from "./modals/ResetCartModal";
import  ConfirmDeleteItemsModal  from "./modals/ConfirmDeleteItemsModal";
import  DecrementModal  from "./modals/DecrementModal";
import NewHeader from '../HeaderComponent/NewHeader.js';
import FooterComponent from '../FooterComponent/FooterComponent.js';
import { checkCouponCode,storeDumyOrder,checkEvent_Currency,getFee } from '../../services/checkoutCardService';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 

   

function CartComponent() {
  const [userData, setUserData] = useState(null);
  const [userFavrt, setUserFavrt] = useState(null);
  const { id } = useParams();
   const { country} = useParams(); 
   
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [availableTickets, setAvailableTickets] = useState([]);
  const [couponId, setcouponId] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountAmounts, setdiscountAmount] = useState(0);
  const [error, setError] = useState(null);
  const [Couponloading, setCouponLoading] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [ShowModal, setshowModal] = useState(false);
  const [showItemDelModal, setShowItemDelModal] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [favoriteText, setFavoriteText] = useState("");
  const [resetSingleCart, setResetSingleCart] = useState("");
  const [isIdMatching, setIsIdMatching] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const isFetchingRef = useRef(false);
  const isFavStateFetchingRef = useRef(false);
  const fetchFavStateRef = useRef();
  const [orders, setOrders] = useState([]);
  const { ordersCount, updateOrdersCount } = useOrders();
  const [items, setItems] = useState([]);
  const [Totalitems, setTotalItems] = useState([]);
  const [total_Price, setTotal_Price] = useState(0);
  const [total_Local_Price, setTotal_Local_Price] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [coupon_code, setDiscountCode] = useState("");
  const [respOrderId, setRespOrderId] = useState("");
  const [Response, setResponse] = useState(null);
  const [deleteItem, setDeleteItem] = useState([]);
  const [resetCardModal, setResetCardModal] = useState(false);
  const [decrementItem, setDecrementItem] = useState(false);
  const [processingFee, setProcessingFee] = useState('');
  const [serviceFee, setServiceFee] = useState(0);
  const [chargeServiceFee, setChargeServiceFee] = useState(0);
  const [Processingvat_fee, setProcessingvat_fee] = useState(0);
  const [isVatCharge, setisVatCharge] = useState('');
  const [countryVat, setCountryVat] = useState(0);
  const stickyDivRef = useRef(null);
  const wrapperRef = useRef(null);
  const nextDivRef = useRef(null);

   
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('scroll', handleScroll);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () =>  {
      window.removeEventListener("resize", handleResize); 
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);
 
  const handleScroll = () => {
    const stickyDiv = stickyDivRef.current;
    const wrapper = wrapperRef.current;
  
    if (stickyDiv && wrapper) {
      const wrapperRect = wrapper.getBoundingClientRect();
      const stickyHeight = stickyDiv.offsetHeight;
  
      // Check if the top of the wrapper is above or at the top of the viewport
      if (wrapperRect.top <= 0) {
        stickyDiv.classList.add('in-view');
        wrapper.classList.add('in-view-wrapper');
      } else {
        stickyDiv.classList.remove('in-view');
        wrapper.classList.remove('in-view-wrapper');
      }
    }
  };

 
  const [ checkoutLocalCurrencyButton, setCheckoutLocalCurrencyButton ] = useState(false);
  const [ BSDCurrencyButton, setBSDCurrencyButton ] = useState(false);

  const getUserCart = (userId) => {
     
  
    if (!userId) {
        console.error("User is not logged in.");
        return null;
    }

    const cart = JSON.parse(localStorage.getItem("cart") || "{}");

    return cart[userId] || {   items: [] };
};

const unSlugify = (country) => {
  return country.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase() );
};

// Now you can use the unSlugify function
const slug = unSlugify(country);
 
useEffect(() => {
  const loginUser = localStorage.getItem("userID");
  const cartDataFromLocalStorage = JSON.parse(localStorage.getItem("CartData")) || {};

  if (cartDataFromLocalStorage[loginUser] && cartDataFromLocalStorage[loginUser].length > 0) {
      const itemsFromLocalStorage = cartDataFromLocalStorage[loginUser];

      // Filter items based on the country
      const filteredItems = itemsFromLocalStorage.filter(item => item.attributes.country === slug);

      // Calculate totalPrice for each item and update items
      const updatedItems = filteredItems.map((item) => ({
          ...item,
          totalPrice: item.quantity * item.per_ticket_price,
          total_Local_currency_price: item.quantity * item.local_currency_price,
      }));

      // Calculate total price and total items
      const totalPrice = updatedItems.reduce((accumulator, currentItem) => {
          return accumulator + currentItem.totalPrice;
      }, 0);

      const totalLocalPrice = updatedItems.reduce((accumulator, currentItem) => {
          return accumulator + currentItem.total_Local_currency_price;
      }, 0);

      let totalItems = 0;
      updatedItems.forEach((item) => {
          totalItems += item.quantity;
      });

      // Update state variables
      setItems(updatedItems);
      setTotalItems(totalItems);
      setTotal_Price(totalPrice);
      setTotal_Local_Price(totalLocalPrice);

      // Update localStorage with updated items and totals for loginUser
      cartDataFromLocalStorage[loginUser] = updatedItems;
      // localStorage.setItem("CartData", JSON.stringify(cartDataFromLocalStorage));
      localStorage.setItem("total_Price", totalPrice);
      localStorage.setItem("total_Items", totalItems);
  }
  fatchCountryFee(slug);

}, [slug]);

const fatchCountryFee = async (slug) => {
 
    try { 
        
        let country = slug; 
  
        const responseData = await getFee(country);
        
        if(responseData.success){
           
          if(responseData.service_fee_enabled == 1){
            setChargeServiceFee(responseData.service_fee);
          }
          if(responseData.fee_enabled == 1){
            setProcessingFee(responseData.fee);
          }
           setisVatCharge(responseData.vat_exclusive);
          setProcessingvat_fee(responseData.vat_fee);
          setCountryVat(responseData.vat_fee);
        }
        
  } catch (error) {
      
  }
  
};

 

useEffect(() => {
  const loginUser = localStorage.getItem("userID");
  const cartDataFromLocalStorage = JSON.parse(localStorage.getItem("CartData")) || {};

  if (cartDataFromLocalStorage[loginUser] && cartDataFromLocalStorage[loginUser].length > 0) {
      const itemsFromLocalStorage = cartDataFromLocalStorage[loginUser];

      // Filter items based on the country
      const filteredItems = itemsFromLocalStorage.filter(item => item.attributes.country === slug);

      // Calculate totalPrice for each item and update items
      const updatedItems = filteredItems.map((item) => ({
          ...item,
          totalPrice: item.quantity * item.per_ticket_price,
          total_Local_currency_price: item.quantity * item.local_currency_price,
           
      }));

      // Calculate total price and total items
      const totalPrice = updatedItems.reduce((accumulator, currentItem) => {
          return accumulator + currentItem.totalPrice;
      }, 0);

      const totalLocalPrice = updatedItems.reduce((accumulator, currentItem) => {
          return accumulator + currentItem.total_Local_currency_price;
      }, 0);

      const totalserviceFee= updatedItems.reduce((accumulator, currentItem) => {
          return accumulator + currentItem.quantity * chargeServiceFee;
      }, 0);

      let totalItems = 0;
      updatedItems.forEach((item) => {
          totalItems += item.quantity;
      });

      // Update state variables
      setServiceFee(totalserviceFee);
      setItems(updatedItems);
      setTotalItems(totalItems);
      setTotal_Price(totalPrice);
      setTotal_Local_Price(totalLocalPrice);

      // Update localStorage with updated items and totals for loginUser
      cartDataFromLocalStorage[loginUser] = updatedItems;
      // localStorage.setItem("CartData", JSON.stringify(cartDataFromLocalStorage));
      localStorage.setItem("total_Price", totalPrice);
      localStorage.setItem("total_Items", totalItems);
  }
  fatchCountryFee(slug);

}, [chargeServiceFee]);



  
  useEffect(() => {
   
    const savedDiscount = localStorage.getItem("discountValue");

 
    if (savedDiscount !== null) {
 
      setdiscountAmount(Number(savedDiscount));
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);  
    }, 2000); 

    return () => clearTimeout(timer);  
  }, []);

  const handleTicketClick = (item) => {
    setSelectedTicket(item);
    setshowModal(true);
  };

  const handleCloseModal = () => {
    setshowModal(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setCouponLoading(true);
    try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
           Swal.fire({
              icon: "info",
              title: "Login Required",
              text: "Please log in to continue.",
          });
          setCouponLoading(false);
          return;  
      }
        let couponCode = coupon_code; 
  
        
        if (!couponCode) {
            couponCode = localStorage.getItem("coupon_code");
        }
  
        if (!couponCode) {
            throw new Error("No coupon code available to submit");
        }
  
        const responseData = await checkCouponCode(authToken, couponCode);
        if (responseData && responseData.success) {
          const discountValue = responseData.data.discount;
          localStorage.setItem("discountValue", discountValue.toString());
          localStorage.setItem("coupon_Id", responseData.data.id.toString());
          setResponse(responseData.data);
          setcouponId(responseData.data.id);
      } else {
          let errorMessage = "An error occurred while processing your request.";
     
          if (responseData && responseData.msg) {
              errorMessage = responseData.msg;
          }
          Swal.fire({
            icon: "error",
            title: "Error",
            text: errorMessage,
        });
     
          throw new Error(errorMessage);
      }
  } catch (error) {
      
      Swal.fire({
          icon: "error",
          title: "Error",
          text: error,
      });
      setCouponLoading(false);
  }
  setCouponLoading(false);
};


  const handleInputChange = (event) => {
    setDiscountCode(event.target.value);
  };
  const handleDeleteItemConfirm = (item) => {
    setDeleteItem(item);
    setShowItemDelModal(true);
  };

  const handleDeleteItem = () => {
    const itemToDelete = deleteItem;
    const loginUser = localStorage.getItem("userID");
    
  
    let cartDataFromLocalStorage = JSON.parse(localStorage.getItem("CartData")) || {};

    if (cartDataFromLocalStorage[loginUser] && cartDataFromLocalStorage[loginUser].length > 0) {
        // Filter out the specific item to be deleted based on item id and country
         const itemsInCountry = cartDataFromLocalStorage[loginUser].filter(
            (cartItem) => cartItem.attributes.country === slug
        );

        // Further filter out the specific item to be deleted
        const updatedItems = itemsInCountry.filter(
            (cartItem) => cartItem.id !== itemToDelete.id
        ); 
        
        const itemsFromOtherCountries = cartDataFromLocalStorage[loginUser].filter(
          (cartItem) => cartItem.attributes.country !== slug
          ); 
 
          
          const updatedCartData = [
              ...itemsFromOtherCountries,
              ...updatedItems
          ];
        setItems(updatedItems);
      

      // Recalculate total price if necessary
      const newTotalPrice = updatedItems.reduce((total, currentItem) => total + currentItem.price, 0);
      const totalLocalPrice = updatedItems.reduce((total_Local_currency_price, currentItem) => total_Local_currency_price + currentItem.total_Local_currency_price, 0);
     
      setTotal_Price(newTotalPrice);
      setTotal_Local_Price(totalLocalPrice);
      checkEventCurrency();

      // Update cartDataFromLocalStorage for loginUser
      cartDataFromLocalStorage[loginUser] = updatedCartData;
 
      // Save the updated CartData back to localStorage
      localStorage.setItem("CartData", JSON.stringify(cartDataFromLocalStorage));
      localStorage.setItem("FakeOrder", JSON.stringify(cartDataFromLocalStorage));

      // Update 'orders' in localStorage for compatibility
      localStorage.setItem("orders", JSON.stringify(updatedItems));

      // Calculate total quantity
      const itemsJSON = localStorage.getItem("CartData");
      let totalQuantity = 0;

      if (itemsJSON) {
        const items = JSON.parse(itemsJSON);

        // Filter items by loginUser
        if (items[loginUser]) {
          items[loginUser].forEach((item) => {
            totalQuantity += item.quantity;
          });
        }
      }

      localStorage.setItem("ordersCount", totalQuantity);
      updateOrdersCount(totalQuantity);
      setDeleteItem([]);
      setShowItemDelModal(false);
    }
  };
 
  const handleResetCartModal = (country) => {
    setResetSingleCart(country);
    setResetCardModal(true);
  }
  const handleResetCart = () => {
    setResetCardModal(false);
    
      const loginUser = localStorage.getItem("userID");
      let cartDataFromLocalStorage = JSON.parse(localStorage.getItem("CartData")) || {};

      if (cartDataFromLocalStorage[loginUser] && cartDataFromLocalStorage[loginUser].length > 0) {
     
        const remainingCartItems = cartDataFromLocalStorage[loginUser].filter(
          (cartItem) => cartItem.attributes.country !== resetSingleCart  
      );
 
      if (remainingCartItems.length === 0) {
        delete cartDataFromLocalStorage[loginUser];   
      } else {
          cartDataFromLocalStorage[loginUser] = remainingCartItems;   
      }

        setItems([]);
        setAvailableTickets([]);

        setTotal_Price(0);
        setTotal_Local_Price(0);
        updateOrdersCount(0);

        // Save the updated CartData back to localStorage
        localStorage.setItem("CartData", JSON.stringify(cartDataFromLocalStorage));
        localStorage.removeItem("items");
        localStorage.removeItem("orders");
        localStorage.removeItem("ticketQuantities");
        localStorage.removeItem("eventName");
        localStorage.removeItem("totalPrice");
        localStorage.removeItem("total_Price");
        localStorage.removeItem("discountAmount");
        localStorage.removeItem("discountValue");
        //toast.success("Your cart has been emptied successfully.");
        // Swal.fire(
        //   "Cart Emptied!",
        //   "Your cart has been emptied successfully.",
        //   "success"
        // );
      }
     
  };
 
  /************************************************/
  const [sharedSecret, setSharedSecret] = useState("tqnD.,39Gq");
  const [paymentData, setPaymentData] = useState({
    hash_algorithm: 'HMACSHA256',
    checkoutoption: 'combinedpage',
    language: 'en_US',
    hashExtended: '',
    oid: '#42444',
    storename: '811801256',
    full_bypass: 'false',
    dccSkipOffer: 'false',
    paymentMethod: '',
    timezone: 'Europe/Berlin',
    txndatetime: '2024:06:11-12:15:27',
    txntype: 'sale',
    chargetotal: '15.00',
    authenticateTransaction: 'false',
    currency: '840',
    referencedSchemeTransactionId: '',
    unscheduledCredentialOnFileType: '',
    responseFailURL: 'https://api.tikkets.com/tikket-api/public/api/paymentIntegrationFail',
    responseSuccessURL: 'https://api.tikkets.com/tikket-api/public/api/paymentIntegrationSuccess',
    assignToken: 'false',
    hosteddataid: ''
  });

  const prepareMessageSignature = (callback) => {
    
    
  
    const ignoreSignatureParameters = ["hashExtended"];
    const paymentParameters = Object.entries(paymentData)
      .filter(([key, value]) => value !== "")
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    const messageSignatureContent = Object.keys(paymentParameters)
      .filter(key => !ignoreSignatureParameters.includes(key))
      .sort()
      .map(key => paymentParameters[key]);

   
    const messageSignature = CryptoJS.HmacSHA256(messageSignatureContent.join("|"), sharedSecret);
    const messageSignatureBase64 = CryptoJS.enc.Base64.stringify(messageSignature);
      
     
    //return; 
 
    setPaymentData(prevState => {
      const updatedData = {
        ...prevState,
        hashExtended: messageSignatureBase64
      };
      callback(updatedData);  // Call the callback with the updated data
      return updatedData;
    });
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading_Local, setIsLoading_Local] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(true);
  
  const handleProceedToPayment = async () => {
      
    setIsLoading(true);
    
    prepareMessageSignature(async (updatedPaymentData) => {
      const loginUser = localStorage.getItem("userID");
       
      const available_Items = JSON.parse(localStorage.getItem("CartData"))[loginUser] || {};


        const availableItems = (available_Items).filter(
        (cartItem) => cartItem.attributes.country === slug
    );

     
      if (availableItems.length === 0) {
        Swal.fire('You do not have any tickets in your cart');
        setIsLoading(false);
        return false;
      }
      const ticketsJson = {};
      availableItems.forEach(ticket => {
        ticketsJson[ticket.id] = ticket;
      });
      const coupon_Id = localStorage.getItem("coupon_Id") || '';
      const updatedFormData = {
        cart: JSON.stringify(ticketsJson),
        coupon_id: couponId ? couponId : coupon_Id,
        coupon_discount: discountAmount.toFixed(2),
        sub_total:displayPriceGlobal.toFixed(2), 
        total_without_fee: displayPriceGlobal.toFixed(2),
        totalPrice: sendTotal.toFixed(2),
        currency_id:'840'
      };
      
   
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        Swal.fire({
          title: 'Error!',
          text: 'You are not logged in. Please log in first.',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/', {
              state: { from: location.pathname }
            });
            localStorage.setItem("checkOutNotAuth", '1');
          }
        });
        return;
      }
  
      try {
        const response = await storeDumyOrder(authToken, updatedFormData);
        setIsLoading(false);
        setIsRequestInProgress(true);
        if (response.success) {
          setRespOrderId(response.order.order_id);
          
          if (response.url) {
            // const loginUser = localStorage.getItem("userID");
            // const cartData = JSON.parse(localStorage.getItem("CartData")) || {};
            // delete cartData[loginUser];
            // localStorage.setItem("CartData", JSON.stringify(cartData));
            // updateOrdersCount(0);
            // localStorage.removeItem("items");
            // localStorage.removeItem("orders");
            // localStorage.removeItem("ticketQuantities");
            // localStorage.removeItem("eventName");
            // localStorage.removeItem("totalPrice");
            // localStorage.removeItem("total_Price");
            
            window.location.href = response.url; 
          }
        }
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: 'Network error occurred.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        setIsLoading(false);
        // setIsRequestInProgress(false);
      }
    });
  };

  const handleProceedToPayment_local = async (currencyCode) => { 
        
    setIsLoading_Local(true);
   
    prepareMessageSignature(async (updatedPaymentData) => {
    const loginUser = localStorage.getItem("userID");

        
      const available_Items = JSON.parse(localStorage.getItem("CartData"))[loginUser] || {};


        const availableItems = (available_Items).filter(
        (cartItem) => cartItem.attributes.country === slug
      );

    
      if (availableItems.length === 0) {
        Swal.fire('You do not have any tickets in your cart');
        setIsLoading_Local(false);
        return false;
      }

      const ticketsJson = {};
      availableItems.forEach(ticket => {
        ticketsJson[ticket.id] = ticket;
      }); 
 
      const coupon_Id = localStorage.getItem("coupon_Id") || '';

      let updatedFormData = {};

      if(currencyCode === 'JMD'){
        updatedFormData = {
          cart: JSON.stringify(ticketsJson),
          coupon_id: couponId ? couponId : coupon_Id,
          coupon_discount: discountAmount.toFixed(2),
          total_without_fee: displayPriceLocal.toFixed(2),
          sub_total: displayPriceLocal.toFixed(2),
          totalPrice: sendTotalLocal.toFixed(2),
          currency_id: '388' 
        };
      } else {
        updatedFormData = {
          cart: JSON.stringify(ticketsJson),
          coupon_id: couponId ? couponId : coupon_Id,
          coupon_discount: discountAmount.toFixed(2),
          sub_total: displayPriceGlobal.toFixed(2), 
          total_without_fee: displayPriceGlobal.toFixed(2),
          totalPrice: sendTotal.toFixed(2),
          currency_id: '44'  
        };  
        
         
      }
 
      const authToken = localStorage.getItem("authToken");
  
      if (!authToken) {
        Swal.fire({
          title: 'Error!',
          text: 'You are not logged in. Please log in first.',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/', {
              state: { from: location.pathname }
            });
            localStorage.setItem("checkOutNotAuth", '1');
          }
        });
        return; 
      }
  
      try {
        const response = await storeDumyOrder(authToken, updatedFormData);
        setIsLoading_Local(false);
        if (response.success) {
          setRespOrderId(response.order.order_id);
          if (response.url) {
             if(currencyCode === 'BSD'){
              localStorage.setItem("paymentTotal", sendTotal.toFixed(2));
              localStorage.setItem("paymentOrder", response.order.order_id);
                navigate(`/payment`);
             }else{
               window.location.href = response.url;
             }
            
          }
        }
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: 'Network error occurred.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        setIsLoading_Local(false);
      }
    });
  };
 
    

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.body.classList.add("header-bgms");

    return () => {
      document.body.classList.remove("header-bgms");
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("availableTickets", JSON.stringify(availableTickets));
    localStorage.setItem("totalPrice", totalPrice.toString());
  }, [availableTickets, totalPrice]);
  useEffect(() => {
    const savedTickets =
      JSON.parse(localStorage.getItem("availableTickets")) || availableTickets;
    const savedTotalPrice = parseFloat(
      localStorage.getItem("totalPrice") || totalPrice
    );
    setTickets(savedTickets);
    setTotalPrice(savedTotalPrice);
  }, []);

   

  const handleGoBack = () => {
    navigate(-1); 
  };

  const handleIncrementQuantity = (ticket, ticketPerOrder) => {
    
    if (ticket.quantity >= ticketPerOrder) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `You can only order up to ${ticketPerOrder} tickets per order.`,
      });
      return;
    }
  
    // Update items state
    const updatedItems = items.map((item) =>
      item.id === ticket.id ? { ...item, quantity: item.quantity + 1 } : item
    );
    setItems(updatedItems);
  
    // Calculate total price
    const totalPrice = updatedItems.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.per_ticket_price * currentItem.quantity;
    }, 0);

    const totalPriceLocal = updatedItems.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.local_currency_price * currentItem.quantity;
    }, 0);
    
    setTotalPrice(totalPrice);
    setTotal_Price(totalPrice);
    setTotal_Local_Price(totalPriceLocal);
  
    // Update total_Price in localStorage
    localStorage.removeItem("total_Price");
    localStorage.setItem("total_Price", totalPrice);
  
    // Get loginUser from localStorage
    const loginUser = localStorage.getItem("userID");
  
    // Get FakeOrder from localStorage or initialize as empty object
    let userOrders = JSON.parse(localStorage.getItem("FakeOrder")) || {};
  
    // Ensure userOrders exist for loginUser
    if (!userOrders[loginUser]) {
      userOrders[loginUser] = [];
    }
  
    // Find existing ticket index in userOrders
    const existingTicketIndex = userOrders[loginUser].findIndex(
      (order) => order.id === ticket.id
    );
  
    // Prepare ticket object to update or add
    const attributesObject = {
      eventName: ticket.attributes.eventName,
      event_id: ticket.attributes.event_id,
      country: ticket.attributes.country,
      flag: ticket.attributes.flag,
      image: ticket.attributes.image,
      ticket_per_order: ticketPerOrder,
      user_id: ticket.attributes.user_id,
    };
  
    const ticketObject = {
      id: ticket.id,
      name: ticket.name,
      price: ticket.per_ticket_price * (ticket.quantity + 1),
      per_ticket_price: ticket.per_ticket_price,
      local_currency_price: ticket.local_currency_price,
      total_Local_currency_price: ticket.local_currency_price * (ticket.quantity + 1), 
      quantity: ticket.quantity + 1,
      attributes: attributesObject,
     
    };
  
    // If ticket exists, update it; otherwise, add new ticket
    if (existingTicketIndex !== -1) {
      userOrders[loginUser][existingTicketIndex] = ticketObject;
    } else {
      userOrders[loginUser].push(ticketObject);
    }
  
      // Update FakeOrder in localStorage
      localStorage.setItem("FakeOrder", JSON.stringify(userOrders));
      
      // Get cartData from localStorage or initialize as empty object
    let cartData = JSON.parse(localStorage.getItem("CartData")) || {};

    // Ensure cartData exists for loginUser
    if (!cartData[loginUser]) {
      cartData[loginUser] = [];
    }

    // Find the index of the ticket in cartData[loginUser]
    const cartDataIndex = cartData[loginUser].findIndex(
      (item) => item.id === ticket.id
    );

    // If ticket exists, update it in cartData[loginUser] or push new item
    if (cartDataIndex !== -1) {
      cartData[loginUser][cartDataIndex] = ticketObject;
    } else {
      cartData[loginUser].push(ticketObject);
    }

    // Update cartData in localStorage
    localStorage.setItem("CartData", JSON.stringify(cartData));


    
    const itemsJSON = localStorage.getItem("CartData");

    let totalQuantity = 0;
  
    if (itemsJSON) {
      const items = JSON.parse(itemsJSON);
  
      // Filter items by loginUser
      if (items[loginUser]) {
        items[loginUser].forEach((item) => {
          totalQuantity += item.quantity;
        });
      }
    }
  
    localStorage.setItem("ordersCount", totalQuantity);
    updateOrdersCount(totalQuantity); 

    

  };


  const handleDecrementQuantity = (ticket) => {
 
    if (ticket.quantity > 0) {
      // Update items state to reflect the decreased quantity
      const updatedTickets = items.map((t) =>
        t.id === ticket.id
          ? {
              ...t,
              quantity: t.quantity - 1,
              price: t.per_ticket_price * (t.quantity - 1),
              total_Local_currency_price: t.local_currency_price * (t.quantity - 1),
            }
          : t
      );
  
      // Check if any ticket quantity has reached 0
      const hasZeroQuantity = updatedTickets.some((t) => t.quantity === 0);
        if (hasZeroQuantity) {
        // Swal.fire({
        //   icon: "warning",
        //   title: "Item Quantity Reached 0",
        //   text: "You need to delete this item because its quantity is now 0.",
        // });
        setDecrementItem(true);
        return false;
      }
   
      setItems(updatedTickets);
   
      const loginUser = localStorage.getItem("userID");
      const userOrders = JSON.parse(localStorage.getItem("FakeOrder")) || {};
   
      if (!userOrders[loginUser]) {
        userOrders[loginUser] = [];
      } 
      const existingTicketIndex = userOrders[loginUser].findIndex(
        (order) => order.id === ticket.id
      ); 
      const updatedTicketObject = {
        id: ticket.id,
        name: ticket.name,
        price: ticket.per_ticket_price * (ticket.quantity - 1),
        per_ticket_price: ticket.per_ticket_price,
        local_currency_price: ticket.local_currency_price,
        total_Local_currency_price: ticket.local_currency_price * (ticket.quantity - 1),
        quantity: ticket.quantity - 1,
        attributes: {
          eventName: ticket.attributes.eventName,
          event_id: ticket.attributes.event_id,
          image: ticket.attributes.image,
          country: ticket.attributes.country,
          ticket_per_order: ticket.attributes.ticket_per_order,
          user_id: ticket.attributes.user_id,
        },
      }; 
      if (existingTicketIndex !== -1) {
        userOrders[loginUser][existingTicketIndex] = updatedTicketObject;
      } 
      localStorage.setItem("FakeOrder", JSON.stringify(userOrders));
   
      localStorage.setItem("orders", JSON.stringify(updatedTickets));
   
      const cartData = JSON.parse(localStorage.getItem("CartData")) || {};
   
      if (!cartData[loginUser]) {
        cartData[loginUser] = [];
      } 
      const cartDataIndex = cartData[loginUser].findIndex(
        (item) => item.id === ticket.id
      ); 
      if (cartDataIndex !== -1) {
        cartData[loginUser][cartDataIndex] = updatedTicketObject;
      } 
      localStorage.setItem("CartData", JSON.stringify(cartData));
   
      const totalPrice = updatedTickets.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.per_ticket_price * currentItem.quantity;
      }, 0);
      
      const totalPriceLocal = updatedTickets.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.local_currency_price * currentItem.quantity;
      }, 0);
       
 
      setTotal_Price(totalPrice);
      setTotal_Local_Price(totalPriceLocal);
      localStorage.setItem("total_Price", totalPrice);
  
      // Update orders count
      const itemsJSON = localStorage.getItem("CartData");

      let totalQuantity = 0;
    
      if (itemsJSON) {
        const items = JSON.parse(itemsJSON);
    
        // Filter items by loginUser
        if (items[loginUser]) {
          items[loginUser].forEach((item) => {
            totalQuantity += item.quantity;
          });
        }
      }
    
      localStorage.setItem("ordersCount", totalQuantity);
      updateOrdersCount(totalQuantity); 
    }
  };
  
   
  useEffect(() => {
    const storedFavoriteState = localStorage.getItem("favoriteState");

    if (storedFavoriteState) {
      const parsedFavoriteState = JSON.parse(storedFavoriteState);
      setIsFavorited(parsedFavoriteState.isFavorited);
      setFavoriteText(parsedFavoriteState.favoriteText);
    }
  }, []);

  useEffect(() => {
    const commaSeparatedString = userFavrt || "";
    const idToMatch = id || "";

    const idArray = commaSeparatedString.split(",");
    const isMatching = idArray.includes(idToMatch);

    setIsIdMatching(isMatching);
    if (isMatching) {
      setIsFavorited(true);
      setFavoriteText("UnFavorite");
    } else {
      setIsFavorited(false);
      setFavoriteText("Favorite");
    }
  }, [userFavrt, id]);

  if (error) {
    return <div>Error: {error}</div>;
  }
  const discount =
    Response !== null && Response.discount !== null ? Response.discount : 0;
  
  
    const discountAmount =
    discount === 0
      ? total_Price * (discountAmounts / 100)
      : total_Price * (discount / 100); 

      const discountAmountLocal =
      discount === 0
        ? total_Local_Price * (discountAmounts / 100)
        : total_Local_Price * (discount / 100); 

       
const discountAmountResultGlobal = discountAmount - total_Price;
const displayPriceGlobal = discountAmountResultGlobal === 0 ? total_Price : Math.abs(discountAmountResultGlobal);
// const fee = displayPriceGlobal * processingFee / 100;

const get_fee = total_Price + serviceFee;
const fee = get_fee * processingFee / 100;
  
var orderVatGlobal = 0;

if(isVatCharge == '1'){
  var orderVatGlobal = (fee + serviceFee ) * countryVat / 100;
  
}else{
  var orderVatGlobal = (fee + serviceFee + total_Price) * countryVat / 100;
  
}

const sendTotal = displayPriceGlobal + fee + orderVatGlobal + serviceFee;


const discountAmountResultLocal = discountAmountLocal - total_Local_Price;
const displayPriceLocal = discountAmountResultLocal === 0 ? total_Local_Price : Math.abs(discountAmountResultLocal);
// const feeLocal = displayPriceLocal * processingFee / 100;
const feeLocal = processingFee; 
const orderVatLocal = feeLocal * countryVat / 100;
const sendTotalLocal = displayPriceLocal + feeLocal + orderVatLocal + serviceFee; 

const checkEventCurrency = async () => {
     
  if (Array.isArray(items)) {
    
    
    const eventIds = items.map(item => item.attributes.event_id).join(',');
    const tiketsIds = items.map(item => item.id).join(',');

  
    if (eventIds && tiketsIds) {
      try {
        const response = await checkEvent_Currency(eventIds, tiketsIds);
         
      if (response.events) {
        const hasJMD = response.events.every(event => event.code === 'JMD');
        if (hasJMD) {

          setCheckoutLocalCurrencyButton(true);
           
        } else {
          setCheckoutLocalCurrencyButton(false);
        }
        

        const hasBSD = response.events.every(event => event.code === 'BSD');
        if (hasBSD) {

          setBSDCurrencyButton(true);
           
        } else {
          setBSDCurrencyButton(false);
        }

        
      } 
      
      if(response.tickets_total_price){


      }



      } catch (error) {
        console.log('Error:', error);
      }
    }
  }
};

  useEffect(() => {
  

    checkEventCurrency();
  }, [items, checkoutLocalCurrencyButton,BSDCurrencyButton]);

  

  
  return (
    <>

      {/* <PaymentForm/> */}
      <Helmet>
      <link href="/New-css/bootstrap.min.css" rel="stylesheet" />
        <link href="/New-css/animate.css" rel="stylesheet" />
        <link href="/New-css/custom.css" rel="stylesheet" />
        <link href="/New-css/slick-theme.css" rel="stylesheet" />
        <link href="/New-css/slick.css" rel="stylesheet" />
      </Helmet> 


      {loading ? (
        <section className="pt-lg-5 pt-md-5 pt-3 bg-silver2">
          <div className="container3">
            <div className="block-element m-b-30">
            </div>
            <div className="block-element loader-mobile-screen"   style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div></div>
        </section>
      ) : (
        <>
    <NewHeader/>
    <div>
     
      {loading ? (
                <LoadingSpinner />
              ) : (
                <>
      <section className="pt-lg-5 pt-md-5 pt-3 bg-silver2">
        <div className="container">
          <div className="d-block w-100 py-2">
            <h3 className="mt-0 mb-2 pt-0 pb-1 text-black text-29 mob-text-25 poppins-700">
              Cart Checkout
            </h3>
            <h5 className="text-black text-15 d-flex align-items-center justify-content-start" >
              <Link to="/" style={{'color':'black'}}> <span> Home </span> </Link>
             
              <i className="fa fa-solid fa-circle text-6 text-muted ms-2 me-2" />
              <Link to="/cart-items" style={{'color':'black'}}> <span> Cart </span> </Link>
              
               
            </h5>
          </div>
        </div>
      </section>
      <section className="py-5 bg-silver2">
        <div className="container">
          <div className="d-flex align-items-start justify-content-start flex-wrap">
            <div className="order-checkout-left pe-lg-4 pe-md-4 pe-0">
              <div className="d-block w-100 mb-4">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-md-8 col-sm-8 col-10">
                    <div className="d-flex w-100 align-items-start justify-content-start">
                      <div className="d-inline-block text-center">
                        <i className="fa fa-check text-24 mb-2 col-green"> </i>
                        <h5 className="text-black m-0 poppins-500 text-15">
                          Cart
                        </h5>
                      </div>
                      <div className="w-100 pt-2 ps-2 pe-2">
                        <span className="bg-silver3 d-block w-100 line-2 overflow-hidden position-relative">
                          <b className="w-50 p-1 bg-green d-inline-block position-absolute top-0 start-0"></b>
                        </span>
                      </div>
                      <div className="d-inline-block text-center">
                      <i className="fa fa-circle text-10 mb-2 text-muted"></i>
                        <h5 className="text-muted m-0 poppins-500 text-15">
                          Payment
                        </h5>
                      </div>
                    </div>
                  </div>

                  
                </div>
              </div>
              <div className="d-block w-100 mb-3 bg-white rounded-3 shadow-normal">
                <div className="d-block w-100 px-3 py-4">
                  <h3 className="text-black poppins-600 mt-0 mb-2 text-20">
                    Cart
                  </h3>
                  {/* <p className="text-14 text-muted poppins-400 m-0">
                    {ordersCount ? ordersCount + " items" : "no Cart"}
                  </p> */}
                </div>
                <div className="d-block w-100">
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 tab-width1 d-lg-table-cell d-md-table-cell d-none">
                          Product
                        </th>
 
  
                        <th className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 d-lg-table-cell d-md-table-cell d-none">
                          Price
                        </th>

                      

                        <th className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 d-lg-table-cell d-md-table-cell d-none">
                          Quantity
                        </th>
                        <th
                          className="text-muted text-15 poppins-500 py-3 px-3 bg-silver4 d-lg-table-cell d-md-table-cell d-none"
                          colSpan={2}
                        >
                          Total Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item) => {
                        const totalPrice = (
                          item.quantity * item.per_ticket_price 
                        ).toFixed(2);

                        return (
                          <tr key={item.id}>
                            <td className="px-3 py-3">
                              <div className="d-flex w-100 align-items-center justify-content-start">
                                <div className="d-inline-block me-3">
                                  <img
                                    className="custom-image3"
                                    src={item.attributes.image}
                                    alt={item.name}
                                  />
                                   
                                </div>
                                <div className="d-inline-block">
                                <h5 
                                  className="text-black text-15 poppins-600 mt-0 mb-2 mob_style" 
                                  onClick={() => handleTicketClick(item)}
                                  style={{ cursor: 'pointer' }}
                              > 
                                  {item.name}
                                 
                              </h5>
                              <span>
                                <img src={item.attributes.flag} className="flg rounded-2" height="20" width="30" alt="Flag" />
                              </span>
                                  <span className="text-black text-15  mt-2 poppins-600 d-lg-none d-md-none d-inline-block">
                                  ${item.per_ticket_price.toFixed(2)}
                                  </span>
                                  {/* <span className="bg-green px-2 text-white py-1 text-13 rounded-3 d-inline-block">
                                    <img
                                      src="/images/ticket-icon.png"
                                      className="d-inline-block me-1 text-13 poppins-500"
                                    />
                                    {item.type}
                                  </span> */}
                                </div>
                              </div>
                            </td>
                             
                             
                            <td className="text-muted text-15 px-3 py-3 poppins-500 d-lg-table-cell d-md-table-cell d-none">
                              ${item.per_ticket_price.toFixed(2)}
                            </td>
                            <td className="px-3 py-3" id="mob-tresh">
                              <div className="quantity-container d-inline-block border rounded-4 border-color-silver overflow-hidden">
                                <button
                                  data-decrease
                                  className="text-black bg-transparent d-inline-block py-1 px-2 text-20 poppins-500 border-0"
                                  onClick={() => handleDecrementQuantity(item)}
                                >
                                  -
                                </button>
                                <span className="text-black bg-transparent d-inline-block p-1 text-18 poppins-500">
                                  {item.quantity}
                                </span>
                                <button
                                  data-increase
                                  className="border-0 text-black bg-transparent d-inline-block p-1 text-20 poppins-500"
                                  onClick={() =>
                                    handleIncrementQuantity(
                                      item,
                                      item.attributes.ticket_per_order
                                    )
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td className="text-black text-15 px-3 py-3 poppins-600 d-lg-table-cell d-md-table-cell d-none">
                              ${totalPrice}
                            </td>
                            <td className="text-muted text-14 px-lg-3 px-md-3 px-sm-1 px-1 py-3 poppins-500 d-lg-table-cell d-md-table-cell">
                              <button
                                className="border-0 bg-transparent"
                                // onClick={() => handleDeleteItem(item)}
                                onClick={() => handleDeleteItemConfirm(item)}
                              >
                                <i className="fa fa-trash text-danger"></i>
                              </button> 
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {selectedTicket && (
                            <div
                              className={`modal modal-open
                        ${ShowModal ? "show" : ""}`}
                              style={{ display: ShowModal ? "block" : "none" }}
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="ticketModal"
                              aria-hidden={!ShowModal}
                            >
                              <div className="modal-dialog">
                                <div
                                  className="modal-content"
                                  style={{
                                    backgroundColor: "#8626db",
                                    color: "white",
                                    textAlign: "center",
                                    borderRadius: "18px",
                                  }}
                                >
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title text-center"
                                      id="ticketModalTitle"
                                    >
                                      {selectedTicket.name}
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      onClick={handleCloseModal}
                                    ></button>
                                  </div>
                                  <div
                                    className="modal-body"
                                    style={{ fontSize: "16px" }}
                                  >
                                    {selectedTicket.description}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                </div>
              </div>
              <div className="d-block w-100 mt-3 mb-3" onClick={handleGoBack}>
              <button className="text-black border-0 bg-transparent text-14 poppins-600 py-2 d-flex align-items-center" style={{ marginLeft: '5px' }}>
              <i className="fa fa-angle-left text-black text-15 me-2"></i>
              Continue Shopping
            </button>

              </div>
            </div>
            <div className="order-checkout-right ps-lg-3 ps-md-3 ps-0  pe-lg-3 pe-md-3 pe-0">
         
              <div className="d-block w-100 bg-white rounded-3 p-3 shadow-normal mb-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h3 className="text-black text-20 poppins-700 m-0">
                    Order Summary
                  </h3>
                  <span className="text-black  ">
                      <button
                      className="border-0 bg-transparent"
                      onClick={() => handleResetCartModal(slug)}
                      >
                      <i className="fa fa-trash text-danger"></i>
                      </button>
                  </span>
                </div>
                <div className="d-block w-100">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14">
                          Sub Total
                        </td>
                        <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14 text-end">
                          ${total_Price.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14">
                       
                          Discount 
                        </td>
                        <td className="col-green pt-2 pb-2 ps-0 pe-0 poppins-400 text-14 text-end">
                         
                          ${discountAmount.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14">
                          
                        Service Fee  ($2.00/ticket)
                        </td>
                        <td className="col-green pt-2 pb-2 ps-0 pe-0 poppins-400 text-14 text-end">
                        
                          ${(serviceFee.toFixed(2))}
                          
                        </td>
                      </tr>
                      <tr>
                        <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14">
                          
                        Processing Fee  ({processingFee}%)
                        </td>
                        <td className="col-green pt-2 pb-2 ps-0 pe-0 poppins-400 text-14 text-end">
                        
                          ${(fee.toFixed(2))}
                          
                        </td>
                      </tr>
                      <tr>
                        <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14">
                          
                        Vat Fee ({Processingvat_fee}%)
                        </td>
                        <td className="col-green pt-2 pb-2 ps-0 pe-0 poppins-400 text-14 text-end">
                        
                          ${(orderVatGlobal).toFixed(2)}
                          
                        </td>
                      </tr>
                      {/* <tr>
                    <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14  border-bottom border-color-silver pb-2"> Tax </td>
                    <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-400 text-14 text-end  border-bottom border-color-silver pb-2"> $5.79 </td>
                  </tr> */}
                      <tr>
                        <td className="text-black pt-2 pb-2 ps-0 pe-0 poppins-600 text-15">
                          Total
                        </td>
                        <td className="col-green pt-2 pb-2 ps-0 pe-0 poppins-600 text-15 text-end">
                          ${sendTotal.toFixed(2)}
                          <span className="text-black text-13 d-block w-100 poppins-400 text-end font-italic">
                            (VAT included if applicable)
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-block w-100 mt-2">
                <form
                    className="border border-color-silver rounded-3 w-100 d-flex w-100 align-items-center justify-content-between p-1"
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="text"
                      placeholder="Discount codes / Gifts"
                      name="coupon_code"
                      value={coupon_code}
                      onChange={handleInputChange}
                      className="text-black w-100 outline-0 text-14 poppins-400 border-0 ps-2 pe-2 pt-2 pb-2"
                    />
                    <button
                      type="submit"
                      disabled={Couponloading} 
                      className="m-0 poppins-600 text-14 col-green border-0 bg-transparent pt-2 pb-2 ps-2 pe-2"
                    >
                      {Couponloading ? 'Applying...' : 'Apply'}
                    </button>
                  </form>
                </div>
              </div>
              <div className="d-block w-100">
              {checkoutLocalCurrencyButton  ? (
                    <>                      <button
                        className="custombtn w-100 mb-2"
                        onClick={handleProceedToPayment}
                      >
                        {isLoading ? 'Processing...' : 'Checkout With USD $' +  sendTotal.toFixed(2) }
                      </button>  

                      <button
                      className="custombtn w-100"
                      onClick={() => handleProceedToPayment_local('JMD')}
                      >
                      {isLoading_Local ? 'Processing...' : 'Checkout With JMD $' + sendTotalLocal.toFixed(2) }
                      </button>
                    </>
   
                    ) : ( 

                      <>

                      {BSDCurrencyButton && (
                        <button
                          className="custombtn w-100 mb-2"
                          onClick={() => handleProceedToPayment_local('BSD')}
                        >
                          {isLoading_Local ? 'Processing...' : 'Checkout With BSD $' + sendTotal.toFixed(2)}
                        </button>
                      )}
                     
                  
 

                      <button
                        className="custombtn  w-100"
                        onClick={handleProceedToPayment}
                        
                         
                      >
                        {isLoading ? 'Processing...' : 'Checkout With USD $' + sendTotal.toFixed(2)}
                      </button>
                      </>

                    )}

                <br />
                <button
                  className="mt-2 w-100 btn btn-danger d-none"
                  onClick={handleResetCart}
                >
                  Reset Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      </> )}
    </div>
    <ToastContainer />
    {resetCardModal && <ResetCartModal setResetCardModal={setResetCardModal} isMobile={isMobile} handleResetCart={handleResetCart} />}
    {decrementItem && <DecrementModal setDecrementItem={setDecrementItem} isMobile={isMobile}  />}
    {showItemDelModal && <ConfirmDeleteItemsModal showItemDelModal={showItemDelModal} setShowItemDelModal={setShowItemDelModal} isMobile={isMobile} handleDeleteItem={handleDeleteItem} />}
    <FooterComponent/> 
    </>
      )}
    </>

  );
}

export default CartComponent;
