import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomeComponent from '../components/HomeComponent/HomeComponent.js';
import HotelsComponent from '../components/HotelComponent/HotelComponent.js';
import LatestHomeComponent from '../components/HomeComponent/LatestHomeComponent.js';
import FilterLatest_HomeComponent from '../components/HomeComponent/FilterLatest_HomeComponent.js';
import NewHomeComponent from "../components/HomeComponent/NewHomeComponent.js";
import BlogComponent from '../components/BlogComponent/BlogComponent.js';
import BLogDetailComponent from '../components/BlogComponent/BLogDetailComponent.js';
import TermsAndConditionsComponent from '../components/Terms_Conditions/Terms_and_Conditions_Component.js';
import Privacy_policyComponent from '../components/Privacy_policy/PrivacyPolicyComponent.js';
import PageComponent from '../components/PageComponent/PageComponent.js';
import RefundPolicy_Component from '../components/Refund_Policy/RefundPolicyComponent.js';
import ContactComponent from '../components/ContactComponent/ContactComponent.js';
import Login from '../auth/Login';
import ResetPassword from '../auth/ResetPassword';
import Register from '../auth/Register';
import MyTicketComponent from '../components/MyTicketComponent/MyTicketComponent.js';
import FaqComponent from '../components/FaqComponent/FaqComponent.js';
import CartComponent from '../components/CartComponent/CartComponent.js';
import PaymentForm from '../components/CartComponent/PaymentComponent.js';
import MianCartComponent from '../components/CartComponent/-09-09-BackupCartComponent.js';
import CartItemComponent from '../components/CartComponent/CartItemComponent.js';
import CartCheckout from '../components/CartComponent/CartCheckout.js';
import AllEventComponent from '../components/EventComponent/AllEventComponent.js';
import EventTikketComponent from '../components/EventComponent/EventTikketComponent.js';
import ThankYouComponent from '../components/ThankYouComponent/ThankYouComponent.js';
import ErrorComponent from '../components/ThankYouComponent/ErrorComponent.js';
import TransferTicketComponent from '../components/TransferTicketComponent/TransferTicketComponent.js';
import TransferringComponent from '../components/TransferTicketComponent/TransferringComponent.js';
import UserProfileComponent from '../components/UserProfileComponent/UserProfileComponent.js';
import NewUserProfileComponent from '../components/UserProfileComponent/NewUserProfileComponent.js';
import EditUserProfileComponent from '../components/UserProfileComponent/EditUserProfileComponent.js';
import ChangePasswordComponent from '../components/UserProfileComponent/ChangePasswordComponent.js';
import SearchDataComponent from '../components/SearchDataComponent/SearchDataComponent.js';
import OrganizersProfileComponent from '../components/OrganizersProfileComponent/OrganizersProfileComponent.js';
import OrderTicketPrint from '../components/MyTicketComponent/OrderTicketPrint.js';
import { OrdersProvider } from '../hooks/SettingContext';
 

const FrontRoutes = () => {
  const role = localStorage.getItem('role');
  const [roleValue, setRoleValue] = useState(false);

  useEffect(() =>{

    setRoleValue(role);

  }, [role]);
  
 

  return (
    <Router>
      <OrdersProvider>
        <Routes>
          <Route path="/" element={<> <LatestHomeComponent /></>} /> 
          <Route path="/new-home" element={<> <LatestHomeComponent /></>} /> 
          <Route path="/page/:slug" element={<> <PageComponent /></>} />
          <Route path="/home" element={<>   <FilterLatest_HomeComponent /> </>} />
          <Route path="/hotels" element={<>   <HotelsComponent /> </>} />
          <Route path="/all-blogs" element={<> <BlogComponent /></>} />
          {/* <Route path="/terms-and-conditions" element={<> <TermsAndConditionsComponent /></>} />
          <Route path="/privacy-policy" element={<> <Privacy_policyComponent /></>} />
          <Route path="/refund-policy" element={<> <RefundPolicy_Component /></>} /> */}
          <Route path="/contact" element={<><ContactComponent/></>} />
          <Route path="/user/login" element={<><Login/></>} />
          <Route path="/user/reset-password" element={<><ResetPassword/></>} />
          <Route path="/user/register" element={<><Register/></>} />
          {roleValue === 'user' ? (
          <>
          <Route path="/user/my-tickets" element={<><MyTicketComponent/></>} />
          </>
          ) : (
            <>
             <Route path="/user/login" element={<><Login/></>} />
            </>
          )}
          <Route path="/order-ticket-print" element={<> <OrderTicketPrint />  </>} />
          <Route path="/faq" element={<><FaqComponent/></>} />
          <Route path="/transfer-ticket" element={<><TransferTicketComponent/></>} />
          <Route path="/ticket-transferring" element={<><TransferringComponent/></>} />
          <Route path="/cart" element={<><CartComponent/></>} />
          <Route path="/payment" element={<><PaymentForm/></>} /> 
          <Route path="/cart/:country" element={<><CartComponent/></>} />
          <Route path="/cart-main" element={<><MianCartComponent/></>} />
          <Route path="/cart-items" element={<><CartItemComponent/></>} /> 
        
          <Route path="/checkout-card" element={<><CartCheckout/></>} />
          <Route path="/thank-you/" element={<><ThankYouComponent/></>} />
          <Route path="/payment_error" element={<><ErrorComponent/></>} />
          {/* <Route path="/user/profile" element={<><UserProfileComponent/></>} /> */}
           
          <Route path="/blog-detail/:id/:title" element={<><BLogDetailComponent /></>} />
          <Route path="/general-search" element={<> <SearchDataComponent /></>} />
          <Route path="/all-events" element={<><AllEventComponent /></>} />
          <Route path="/event/:id/:name" element={<><EventTikketComponent /></>} />
          <Route path="/view-organizers-profile/:id" element={<> <OrganizersProfileComponent />  </>} />
          <Route path="/user/profile" element={<><NewUserProfileComponent/></>} />
          <Route path="/user/edit-profile" element={<><EditUserProfileComponent/></>} />
          <Route path="/user/change-password" element={<><ChangePasswordComponent/></>} />
          <Route path="/user/change-password" element={<><ChangePasswordComponent/></>} />
        </Routes>
      </OrdersProvider>
    </Router>
  );
};

export default FrontRoutes;
