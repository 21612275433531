import React, {useEffect,useState, useCallback } from 'react';
import Swal from "sweetalert2";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Gallery from "react-photo-gallery";
import Measure from 'react-measure';
import Carousel, { Modal, ModalGateway } from "react-images";


const TicketSideBar = ({
  handleAddToCart,
  setOpenSideModal,
  openModal,
  openSideModal, 
  selectedTickets,
  setSelectedTickets, 
  handleClose,
  evtData,
  setOrders,
  setAvailableTickets,
  ordersCount,
  updateOrdersCount,
  availableTickets,
  orders,
  events,
  setticketActiveTab,
  ticketactiveTab,
  isticketLoading,
  setEvtData,
  setisticketLoading,
  handleTicketClick,
  setshowDescriptionModal,
  showDescriptionModal,
  setNotLogin,
  setTicketNotselect
   
 }) => {
    const navigate = useNavigate();
    
   
  
  const handleTabClick = (tab) => {
    setticketActiveTab(tab);
  }; 
  const handleCloseDescription = () => {
     
    setshowDescriptionModal(false); 
  }; 
  
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [width, setWidth] = useState(-1);

  const handleResize = useCallback((contentRect) => {
    setWidth(contentRect.bounds.width);
  }, []);


  const getRandomDimension = () => Math.floor(Math.random() * 2) + 1;

  const galleryImages = evtData && evtData.gallery && evtData !== null
    ? evtData.gallery.split(',').map(photo => ({
        src: `${evtData.imagePath}${photo.trim()}`,
        width: getRandomDimension(),
        height: getRandomDimension()
      }))
    : [];
  
 

const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setIsOpen(true);
    };

    const generateSlug = (name) => {
      return name
        
        .toLowerCase()
        .trim() 
        .replace(/[^\w\s-]/g, '')  
        .replace(/\s+/g, '-') 
        .replace(/-+/g, '-');  
    };
    
const handleContinue_checkout = () => {
    // if (locationStorage) {

    const loginUser = localStorage.getItem("userID");

   

      if (!loginUser) {
        setNotLogin(true);
          return;
      }
       
      

    if (orders && orders.length > 0) {
      const ordersFromLocalStorage = JSON.parse(
        localStorage.getItem("orders") || "[]"
      );
      
 
      const allCartData = JSON.parse(localStorage.getItem('CartData')) || {};
      allCartData[localStorage.getItem("userID")] = ordersFromLocalStorage;
   
    
      localStorage.setItem('CartData', JSON.stringify(allCartData));
       
      localStorage.setItem("items", JSON.stringify(ordersFromLocalStorage));
       
    
      const itemsJSON = localStorage.getItem("CartData");
  
      let totalQuantity = 0;
    
      if (itemsJSON) {
        const items = JSON.parse(itemsJSON);
    
        // Filter items by loginUser
        if (items[loginUser]) {
          items[loginUser].forEach((item) => {
            totalQuantity += item.quantity;
          });
        }
      }
    
      localStorage.setItem("ordersCount", totalQuantity);
      updateOrdersCount(totalQuantity); 

          const lastIndex = ordersFromLocalStorage
          .map((item, index) => ({ ...item, index })) // Include the index in the item
          .reverse() // Reverse the array to find the last occurrence easily
          .find(item => item.attributes.country) // Find the first occurrence in the reversed array
          ?.index; // Get the original index
          if (lastIndex !== undefined) {
            const lastItem = ordersFromLocalStorage[lastIndex];
            const lastCountry = lastItem.attributes.country;

            const slugCountry = generateSlug(lastCountry);

            navigate(`/cart/${slugCountry}`);
          }
  }else{
    setTicketNotselect(true);
    return;
  };

}


      const handleIncrementQuantity = (ticket, ticketPerOrder,img,eventname,country_flag,country_name,is_vat_accept) => {
      
        const loginUser = localStorage.getItem("userID");
         
        const allCartDataByUser = JSON.parse(localStorage.getItem('FakeOrder')) || {};
        const ordersFromLocalStorage = allCartDataByUser[loginUser] || [];
     
        const existingTicketIndex = ordersFromLocalStorage.findIndex(
            (order) => order.id === ticket.id
        );
    
        if (existingTicketIndex !== -1) { 
            const updatedOrders = [...ordersFromLocalStorage];
            const existingOrder = updatedOrders[existingTicketIndex];
            if (existingOrder.quantity === ticketPerOrder) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `You can only order up to ${ticketPerOrder} tickets per order.`,
                });
                return ticket.quantity;
            }
            existingOrder.quantity++;
            existingOrder.price = existingOrder.quantity * existingOrder.per_ticket_price;
            setOrders(updatedOrders);
            allCartDataByUser[loginUser] = updatedOrders;
            localStorage.setItem('FakeOrder', JSON.stringify(allCartDataByUser));
            localStorage.setItem("orders", JSON.stringify(updatedOrders));
        } else {
            const attributesObject = {
                eventName: eventname,
                event_id: ticket.event_id,
                flag: country_flag,
                country: country_name,
                image: img,
                ticket_per_order: ticket.ticket_per_order,
                user_id: ticket.user_id,
                is_vat_accept: is_vat_accept, 
            };
    
            const ticketObject = {
                id: ticket.id,
                name: ticket.name,
                description: ticket.description,
                type: ticket.type,
                per_ticket_price: ticket.price,
                price: ticket.price * (ticket.quantity + 1),
                local_currency_price: ticket.local_currency_price, 
                quantity: ticket.quantity + 1,
                attributes: attributesObject,
            };
    
            const newOrders = [...ordersFromLocalStorage, ticketObject];
            setOrders(newOrders);
     
            allCartDataByUser[loginUser] = newOrders;
            localStorage.setItem('FakeOrder', JSON.stringify(allCartDataByUser));
     
            localStorage.setItem("orders", JSON.stringify(newOrders));
        }
     
        const updatedTickets = availableTickets.map((t) => {
            if (t.id === ticket.id) { 
                const updatedQuantity = t.quantity + 1;
                const updatedPrice = updatedQuantity * t.price;
                return {
                    ...t,
                    quantity: updatedQuantity,
                    selected_price: updatedPrice,
                };
            }
            return t;
        });
    
        setAvailableTickets(updatedTickets);

        if(selectedTickets.length > 0){
          const updatedSelectedTickets = selectedTickets.map((t) => {
            if (t.id === ticket.id) {
                const updatedQuantity = t.quantity + 1;
                const updatedTotalPrice = updatedQuantity * t.price;
                const updatedLocalTotalPrice = updatedQuantity * t.local_currency_price;
                return {
                    ...t,
                    quantity: updatedQuantity,
                    selected_price: updatedTotalPrice,
                    selected_Localprice: updatedLocalTotalPrice,
                };
            }
            return t;
        });
    
        setSelectedTickets(updatedSelectedTickets);

        }
     
       
    };



   

    const handleDecrementQuantity = (ticket) => {
      if (ticket.quantity > 0) {
          
  
          const updatedTickets = availableTickets.map((t) => {
              if (t.id === ticket.id) {
                  const updatedQuantity = t.quantity - 1;
                  const updatedPrice = updatedQuantity * t.price;
                  const updatedLocalTotalPrice = updatedQuantity * t.local_currency_price;
                  return {
                      ...t,
                      quantity: updatedQuantity,
                      selected_price: updatedPrice,
                      selected_Localprice: updatedLocalTotalPrice,
                  };
              }
              return t;
          });
          setAvailableTickets(updatedTickets);
  
          const loginUser = localStorage.getItem("userID");
          const allCartDataByUser = JSON.parse(localStorage.getItem('FakeOrder')) || {};
          const ordersFromLocalStorage = allCartDataByUser[loginUser] || [];
  
          const updatedOrders = ordersFromLocalStorage
              .map((order) => {
                  if (order.id === ticket.id && order.quantity === 1) {
                      return null;
                  } else if (order.id === ticket.id) {
                      const updatedQuantity = order.quantity - 1;
                      const updatedPrice = ticket.price * updatedQuantity;
                      return { ...order, quantity: updatedQuantity, price: updatedPrice };
                  } else {
                      return order;
                  }
              })
              .filter(Boolean);
  
          allCartDataByUser[loginUser] = updatedOrders;
          localStorage.setItem('FakeOrder', JSON.stringify(allCartDataByUser));
          localStorage.setItem('orders', JSON.stringify(updatedOrders));  
  
          setOrders(updatedOrders);
      }
  };
  


      const [showChild, setShowChild] = useState(-1);

      const handleParentClick = (index,id,name) => {
         
         if(isMobile){
          setShowChild(index === showChild ? -1 : index);
          if(index !== showChild){
            getHandletickets(id,name);
          
          }
         }
          
      };
   
      const handleBuyTicketsClick = () => {
        setEvtData([]);
        setAvailableTickets([]);
        setOpenSideModal(false);
        
      setTimeout(() => {
          setisticketLoading(true);
     }, 1000);

        localStorage.removeItem("orders");
    localStorage.removeItem("FakeOrder");
      
      };
     
  return (
    <div className="bd-example" key={evtData ? evtData.id : ''}> 
     
    <div   className={`offcanvas offcanvas-end ${openSideModal ? '' : ''}`} style={openSideModal ? { transform: 'none',visibility: 'visible' } : { transform: 'translateX(100%)', visibility: 'visible'}}  id="offcanvasRight">
           {isticketLoading ? 
 
           <> 
           
                <div
                className="block-element ticketside_bar"
                style={{ marginTop: "80px" }}
                >
                <LoadingSpinner />
                </div>
            
            
            </> : 
            
            <>

{evtData !== null ? (
                    <>
        <div className="offcanvas-header custom-padding-on-close" >
        <h5 id="offcanvasRightLabel"> </h5>
        <div className='d-flex col-md-12 align-items-center justify-content-between' style={{padding:'0'}}>
          <img src="./images/logo-black.png" width="70px" />
          <div className='d-flex align-items-center gap-1'>
            <p className="  text-reset"  onClick={handleBuyTicketsClick} style={{ cursor: 'pointer',opacity:'0.8', margin: '0' }}><strong>Close </strong></p>
            <img src='./images/close.svg' style={{width: '15px', height: '15px', filter: 'brightness(0) saturate(100%) invert(37%) sepia(82%) saturate(5237%) hue-rotate(339deg) brightness(95%) contrast(94%)'}} aria-hidden="true"  onClick={handleBuyTicketsClick}/>

          {/* <button type="button" className="btn-close text-reset"  onClick={handleBuyTicketsClick} /> */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48" style={{ cursor: 'pointer',opacity:'0.8', width:'30%' }}  onClick={handleBuyTicketsClick}>
            <linearGradient id="wRKXFJsqHCxLE9yyOYHkza_fYgQxDaH069W_gr1" x1="9.858" x2="38.142" y1="9.858" y2="38.142" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#f44f5a"></stop><stop offset=".443" stop-color="#ee3d4a"></stop><stop offset="1" stop-color="#e52030"></stop></linearGradient><path fill="url(#wRKXFJsqHCxLE9yyOYHkza_fYgQxDaH069W_gr1)" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path d="M33.192,28.95L28.243,24l4.95-4.95c0.781-0.781,0.781-2.047,0-2.828l-1.414-1.414	c-0.781-0.781-2.047-0.781-2.828,0L24,19.757l-4.95-4.95c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l4.95,4.95l-4.95,4.95c-0.781,0.781-0.781,2.047,0,2.828l1.414,1.414	c0.781,0.781,2.047,0.781,2.828,0l4.95-4.95l4.95,4.95c0.781,0.781,2.047,0.781,2.828,0l1.414-1.414	C33.973,30.997,33.973,29.731,33.192,28.95z" opacity=".05"></path><path d="M32.839,29.303L27.536,24l5.303-5.303c0.586-0.586,0.586-1.536,0-2.121l-1.414-1.414	c-0.586-0.586-1.536-0.586-2.121,0L24,20.464l-5.303-5.303c-0.586-0.586-1.536-0.586-2.121,0l-1.414,1.414	c-0.586,0.586-0.586,1.536,0,2.121L20.464,24l-5.303,5.303c-0.586,0.586-0.586,1.536,0,2.121l1.414,1.414	c0.586,0.586,1.536,0.586,2.121,0L24,27.536l5.303,5.303c0.586,0.586,1.536,0.586,2.121,0l1.414-1.414	C33.425,30.839,33.425,29.889,32.839,29.303z" opacity=".07"></path><path fill="#fff" d="M31.071,15.515l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414L18.343,32.485	c-0.391,0.391-1.024,0.391-1.414,0l-1.414-1.414c-0.391-0.391-0.391-1.024,0-1.414l14.142-14.142	C30.047,15.124,30.681,15.124,31.071,15.515z"></path><path fill="#fff" d="M32.485,31.071l-1.414,1.414c-0.391,0.391-1.024,0.391-1.414,0L15.515,18.343	c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0l14.142,14.142	C32.876,30.047,32.876,30.681,32.485,31.071z"></path>
            </svg> */}
          </div>
        </div>
        
      </div>
    <div className="offcanvas-body custom-scroll1 pt-0" >
      {evtData.image ?
      <>
       <div className="d-lg-block d-md-block w-100   event-info-div pb-1 rounded-5 bg-white">
        <img src= {evtData.country_flag} className="flg" height="20" width="30"  style={{ position: 'relative' , top:'30px',left:'15px' }} /> 
        <div className="d-lg-inline-block d-md-inline-block head w-100 ">
                          <img
                            className="w-100 rounded-3 mb-4 head_img"
                            src={evtData.imagePath + evtData.image}
                          />
                        </div>
          <div className="d-block w-100">
            <h3 className="mt-0 mb-3 p-0 text-black poppins-600 text-18 ">  {evtData.name} </h3>
          </div>

          
          <div className="d-block w-100 tabCustom">
          <div
                                              className="nav nav-tabs border-0 d-flex gap-5p align-items-center justify-content-between nav-tabs-style2"
                                              id="nav-tab"
                                              role="tablist"
                                            >
                                              <button
                                                className={`nav-link col-md-6 col-sm-6 col-lg-6 col-6 ${
                                                  ticketactiveTab === "info"
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  handleTabClick("info")
                                                }
                                              >
                                                {/* <i class="fa fa-info"  style={{ marginRight: '5px' }}></i> */}
                                                <img style={{ marginRight: '5px' }} src='./icons/info.png' />
                                                 Info
                                              </button>
                                              <button
                                                className={`nav-link col-md-6 col-sm-6 col-lg-6 col-6 ${
                                                  ticketactiveTab === "tickets"
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  handleTabClick("tickets")
                                                }
                                              >
                                                {/* <i class="fa fa-ticket-alt" style={{ marginRight: '5px' }}></i> */}
                                                <img style={{ marginRight: '5px' }} src='./icons/ticket.png' />
                                                Tickets
                                              </button>
                                              {/* <button
                                                className={`nav-link col-md-4 col-sm-4 col-lg-4 col-4 ${
                                                  ticketactiveTab === "photos"
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  handleTabClick("photos")
                                                }
                                              >
                                                
                                                <img style={{ marginRight: '5px' }} src='./icons/Group.png' />
                                                 Photos
                                              </button> */}

                                               
                                            </div>
          </div>
          <div className="d-block w-100">
            <div className="tab-content border-0" id="nav-tabContent">
            {ticketactiveTab === "info" && (
                                      <div
                                      className="tab-pane fade active show"
                                      id="nav-info"
                                      role="tabpanel"
                                      aria-labelledby="nav-info-tab"
                                    >
                                      <div className="d-block w-100 py-3">
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                          <div className="d-inline-block">
                                            <h6 className="text-black poppins-600 m-0 d-flex align-items-center justify-content-start text-14 ">
                                              <img
                                                src="images/calendar-icon2.svg"
                                                className="me-2"
                                              />
                                            
                                            {new Date(evtData.start_time).toLocaleString('en-US', {
                                                    weekday: 'short',
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    // hour12: true,
                                                  })}
                                               </h6>
                                          </div>
                                          <div className="d-inline-block">
                                            
                                          </div>
                                        </div>
                                        <div className="d-block w-100 mb-3">
                                          <h6 className="col-blue3 text-uppercase poppins-600 m-0 d-flex align-items-center justify-content-start text-14 ">
                                            <img
                                              src="images/map-icon2.svg"
                                              className="me-2"
                                            />
                                            <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(evtData.address)}`} target="_blank" rel="noopener noreferrer">{evtData.address}</a>
                                          </h6>
                                        </div>
                                        <div className="d-block w-100">
                                          <h4 className="poppins-600 text-16 mt-0 mb-2 text-black text-uppercase">
                                            Description
                                          </h4>
                                        </div>
                                        <div className="d-block w-100 overflow-auto pb-5">
                                          <p className="text-14 col-grey1 poppins-400 lh-base">
                                          <div dangerouslySetInnerHTML={{ __html: evtData.description }} />

                                          </p>
                                        
                                         
                                        </div>
                                      </div>
                                    </div>
                                    )}
                                    {ticketactiveTab === "tickets" && (
                                       
                                          <div
                                          className="tab-pane tikkets-list-sidecart fade active show"
                                          id="nav-info"
                                          role="tabpanel"
                                          aria-labelledby="nav-info-tab"
                                          >
                                     <div className="d-block w-100 pt-3 pb-0">
                                          <div className="d-block w-100  ">
                                            <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-inline-block">
                                            <h6 className="text-black poppins-600 m-0 d-flex align-items-center justify-content-start text-14 ">
                                              <img
                                                src="images/calendar-icon2.svg"
                                                className="me-2"
                                              />
                                            
                                            {new Date(evtData.start_time).toLocaleString('en-US', {
                                                    weekday: 'short',
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    // hour12: true,
                                                  })}
                                               </h6>
                                          </div>
                                          <div className="d-inline-block">
                                            {/* <img src= {evtData.country_flag} className="flg"  height="20" width="34"/> */}
                                          </div>
                                              {/* <div className="d-inline-block">
                                                <h5 className="text-black mt-0 mb-2 poppins-600 p-0 text-14">
                                                {new Date(evtData.end_time).toLocaleString(
                                              "en-US",
                                                {
                                                  day: "2-digit",
                                                  month: "2-digit",
                                                  year: "numeric",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                  hour12: true,
                                                }
                                              )}
                                                </h5>
                                                  <h4 className="text-uppercase col-blue1 poppins-600 mt-0 mb-3 p-0 text-15 d-none">
                                                  {" "}
                                                  The lounge <br /> {evtData.country_name}
                                                </h4>  
                                              <h6 className="col-blue1 d-flex align-items-center justify-content-start text-12 ">
                                                  <img src="images/info-icon1.png" className="me-1" />
                                                  Please Select a Ticket To Download
                                                </h6>  
                                              </div> */}
                                              {/* <div className="d-inline-block">
                                              <Link
                                                    to={
                                                      "/event/" +
                                                      evtData.id +
                                                      "/" +
                                                      slugify(evtData.name)
                                                    }
                                                  >
                                                  <span className="bg-danger min-width-clear d-inline-block text-white px-2 py-1 rounded-3 text-13">
                                               
                                                  More info
                                                 
                                                </span>
                                                </Link>
                                              </div>   */}
                                            </div>
                                          </div>
                                          <div className='d-flex align-items-center gap-1 pt-2'>
                                            <img style={{ marginRight: '5px' }} width="15px" src='./icons/info.png' />
                                            <p className='m-0'>Click on the ticket name to view details.</p>
                                          </div>
                                          <div className="d-block w-100 mb-3">

                                             <div className="d-block w-100 pt-3 pb-0">
                                          {availableTickets.length > 0 ? (
                              availableTickets.map((ticket,index) => (
                                       
                                       <div   key={ticket.id} className="d-flex align-items-start justify-content-between border-bottom border-dashed border-0 border-color-silver pb-2 mb-1">
                                              <div className="d-inline-block ">
                                                <h5 className="text-black poppins-700 text-15 pointer" style={{ cursor: 'pointer' }}  onClick={() =>  handleTicketClick(ticket)  }>     {ticket.name}</h5>
                                                <div className="quantity-container d-inline-block border rounded-4 border-color-silver overflow-hidden">
                                                <Link
                                             className="text-black bg-transparent d-inline-block py-1 px-2 text-18 poppins-500 border-0"
                                             onClick={() =>
                                               handleDecrementQuantity(ticket)
                                             }
                                           >
                                             -
                                           </Link>
                                           <input
                                             type="text"
                                             className="input-number border-0 text-center outline-0 text-black bg-transparent d-inline-block p-1 text-16 poppins-500"
                                             value={ticket.quantity}
                                           />
                                           <Link
                                             className="border-0 text-black bg-transparent d-inline-block p-1 text-18 poppins-500"
                                             data-increase
                                             onClick={() =>
                                               handleIncrementQuantity(
                                                 ticket,
                                                 ticket.ticket_per_order,
                                                 evtData.imagePath+evtData.image,
                                                 evtData.name,
                                                 evtData.country_flag,
                                                 evtData.country_name,
                                                 evtData.is_vat_accept
                                               )
                                             }
                                           >
                                             +
                                           </Link>
                                                </div>
                                              </div>
                                              <div className="d-inline-block text-end mt-2">
                                                <span className="d-inline-block bg-primary text-13 text-white px-2 py-1 text-white d-flex rounded-3 poppins-500 align-items-center justify-content-start">
                                                  <img src="images/ticket-icon.png" className="me-1" />
                                                  ${ticket.price.toFixed(2)}
                                                </span>
                                                <p className="text-black mt-2 mb-2 p-0 poppins-700 text-15 text-black">
                                                ${ticket.selected_price.toFixed(2)}
                                                </p>
                                                <p className="text-muted m-0 p-0 poppins-400 text-13 text-black">
                                                Max: {ticket.ticket_per_order}
                                                </p>
                                              </div>
                                            </div>

                                 
                                    ))
                            ) : (
                              <p>Tickets not available</p>
                            )}
                      
                         
                                          </div>  
                                           
                                             
                                          </div>
                                          <div className="d-block sidebar-cart-buttons">
                                            <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                  <button
                                                    className="custom-btnN6 px-0 py-2 w-100 lh-lg"
                                                    onClick={openModal}
                                                  >
                                                    <img className="icon-width-1 me-lg-2 me-md-2 me-sm-2 me-1"
                                                      src="/images/cart-icon-side.svg"
                                                    />
                                                    Add to Cart
                                                  </button>
                                                </div>
                                              
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6 ps-0">
                                                  <button
                                                    className="custom-btn3 px-0 py-2 w-100 lh-lg"
                                                    onClick={handleContinue_checkout}
                                                  >
                                                    Buy Now
                                                  </button>
                                                </div>
                                            </div>
                                          </div>
                                        </div>

                                          
                                          </div>
                                                                                
                                    )}
                                    {ticketactiveTab === "photos" && (
                                      <div
                                      className="tab-pane fade active show"
                                      id="nav-photos"
                                      role="tabpanel"
                                      aria-labelledby="nav-photos-tab"
                                    >
                                      <div className="d-block w-100 pt-3" >
                                        <div className="row">

                                        {galleryImages && galleryImages.length > 0 && (
                                          <Measure bounds onResize={handleResize}>
                                            {({ measureRef }) => (
                                              <div ref={measureRef}>
                                                <Gallery
                                                  photos={galleryImages}
                                                  direction="column"
                                                  onClick={openLightbox}
                                                  columns={width > 0 ? Math.ceil(width / 300) : 1}
                                                  imageComponent={({ photo }) => (
                                                    <img
                                                      src={photo.src}
                                                      alt=""
                                                      style={{
                                                        minHeight: '100px',
                                                        maxHeight: '300px',
                                                        width: '100%',
                                                        objectFit: 'cover',
                                                        height: 'auto',
                                                      }}
                                                    />
                                                  )}
                                                />
                                              </div>
                                            )}
                                          </Measure>
)}


 
                                        <ModalGateway>
                                        {viewerIsOpen ? (
                                          <Modal onClose={closeLightbox}>
                                            <Carousel
                                              currentIndex={currentImage}
                                              views={galleryImages.map(x => ({
                                                ...x,
                                                srcset: x.srcSet,
                                                caption: x.title
                                              }))}
                                            />
                                          </Modal>
                                        ) : null}
                                      </ModalGateway>
                                      
                                        {/* {evtData.gallery ? (
                                            evtData.gallery.split(',').map((flag, index) => (
                                              <div className="col-lg-6 col-md-6 col-sm-6 col-6" key={index}>
                                                <div className="d-block w-100">
                                                  <img
                                                    src={'https://api.tikkets.com/public/images/upload/' + flag.trim()}
                                                    className="photos-1 rounded-2 w-100  dddd"
                                                    // onClick={() => handleImageClick(flag)}
                                                  />
                                                </div>
                                              </div>
                                            ))
                                          ) : (
                                            <div className="text-center" style={{ width: '100%' }}>
                                            <p style={{ width: '200px', margin: '0 auto' }}>Gallery not available</p>
                                          </div>
                                          )} */}
                                             
                                        </div>
                                      </div>
                                    </div>
                                    
                                    )}
            </div>
          </div>
        </div>
        </> :null }
       
      </div>  
                    </>
            ) : (
            <div className="d-block w-100 bg-white px-2 py-3 rounded-3 shadow-normal mb-3 mt-5" id="dddddddd">
                <div
                className="block-element loader-mobile-screen ticketside_bar"
                style={{ marginTop: "80px" }}
                >
                <LoadingSpinner />
                </div>
            </div>
            )}
  
   
            
            </>
            
            
            }
           
           
               
       
    </div>
    {/* <div    className={`modal-backdrop fade ${openSideModal ? 'show' : 'hide'}`} onClick={handleBuyTicketsClick}/> */}

    <div
        className={`modal-backdrop fade ${openSideModal ? 'show' : 'hide'}`}
        onClick={showDescriptionModal ? handleCloseDescription : handleBuyTicketsClick}
      />

    </div>
 
  );
};

export default TicketSideBar;

