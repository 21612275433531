import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { fetchFaqData } from '../../services/faqservice';
import '../../App.css';
import { Helmet } from "react-helmet";
import FooterComponent from '../FooterComponent/FooterComponent.js';
import HeaderComponent from '../HeaderComponent/HeaderComponent.js';

function FaqComponent() {
  const [faqs, setFaqs] = useState([]);
  const [expandedIndex, setExpanded] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const isFirstRender = useRef(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetchFaqData();
        setFaqs(response.faqs);
        setIsLoading(false);
      } catch (error) {
        // Handle error
        setIsLoading(false);
      }
    };

    if (isFirstRender.current) {
      isFirstRender.current = false;
      fetchData();
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);

  const handleClick_faq = (nextIndex) => {
    if (expandedIndex === nextIndex) {
      setExpanded(-1);
    } else {
      setExpanded(nextIndex);
    }
  };

  const renderedItems = faqs.map((item, index) => {
    const isExpanded = index === expandedIndex;
    return (
      <div className="accordion-item mb-4 shadow-sm" key={item.id}>
        <h2 className="accordion-header" id={`heading${index}`}>
          <button
            className={`accordion-button ${isExpanded ? '' : 'collapsed'} bg-transparent fw-bold`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${index}`}
            aria-expanded={isExpanded}
            aria-controls={`collapse${index}`}
            onClick={() => handleClick_faq(index)}
          >
            {item.question}
          </button>
        </h2>
        <div
          id={`collapse${index}`}
          className={`accordion-collapse collapse ${isExpanded ? 'show' : ''}`}
          aria-labelledby={`heading${index}`}
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <p>{item.answer}</p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <Helmet>
        
        <link rel="stylesheet" href="https://unpkg.com/bootstrap@5.3.3/dist/css/bootstrap.min.css"/>
        {/* <link rel="stylesheet" href="/css/faq-2.css" /> */}
        <link rel="stylesheet" href="/css/customfaq.css" />
      </Helmet>
      {loading ? (
        <section className="pad-top-20 pad-bot-80  " id=""  >
          <div className="container3">
            <div className="block-element m-b-30" />
            <div className="block-element loader-mobile-screen" style={{ marginTop: '80px' }}>
              <LoadingSpinner />
            </div>
          </div>
        </section>
      ) : (
        <>
          <HeaderComponent />

          <section className="bsb-faq-2 bg-transparent py-3 py-md-5 py-xl-8">
            <div className="container">
              <div className="row gy-5 gy-lg-0">
              <h4 class="title-text7 text-dark mob-text-center mb-2"> FAQs </h4>
              <h5 class="text-black text-15 d-flex align-items-center justify-content-start mb-5"><a class="text-black" href="/"> Home </a><svg class="svg-inline--fa fa-circle text-6 text-muted ms-2 me-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" style={{width: "9px;"}}><path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z"></path></svg><span> FAQs </span></h5>
                <div className="col-12  ">
                  <div className="row justify-content-xl-center">
                    <div className="col-12 col-xl-12">
                      <div className="accordion accordion-flush" id="accordionExample">
                        {isLoading ? (
                          <LoadingSpinner />
                        ) : (
                          renderedItems.length > 0 ? (
                            renderedItems
                          ) : (
                            <p className="text-success" style={{ fontSize: 16, fontWeight: 600 }}>
                              Note: The Trinbago 2023, Commonwealth Youth Games Faq's can be found <Link to="https://www.trinbago2023.com/tickets">here</Link>
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <FooterComponent />
        </>
      )}
    </>
  );
}

export default FaqComponent;
