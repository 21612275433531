import React, { useState, useEffect} from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useParams, useNavigate,useLocation  } from "react-router-dom";
import { sendPayment } from '../../services/checkoutCardService';
import Swal from "sweetalert2";
 
const validationSchema = yup.object().shape({
  cardnumber: yup
    .string()
    .required('Card Number is required')
    .matches(/^[0-9]{16,19}$/, 'Card Number must be between 16-19 digits'),
  name: yup.string().required('Card Holder Name is required'),
  exp_month: yup.string().required('Expiry Month is required'),
  exp_year: yup.string().required('Expiry Year is required'),
  cvc: yup
    .string()
    .required('CVC is required')
    .matches(/^[0-9]{3,4}$/, 'CVC must be 3 or 4 digits'),
  checkbox1: yup.boolean().oneOf([true], 'You must acknowledge the charge'),
  checkbox2: yup.boolean().oneOf([true], 'You must accept the invoice terms'),
}); 


const PaymentForm = ({activeTab,setActiveTab}) => {
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);
  const [orderId, setOrderId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
    const { register,setValue, handleSubmit, formState: { errors } } = useForm({
      resolver: yupResolver(validationSchema),
    });
    
    
     
  useEffect(() => {
      const total_amount = localStorage.getItem("paymentTotal");
      setTotalAmount(total_amount);
      const paymentOrderID = localStorage.getItem("paymentOrder");
      setOrderId(paymentOrderID);
      
  }, []);

  const onSubmit = async (data) => {

    setValue('orderId', orderId);
    setValue('total', totalAmount);
    setIsLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        Swal.fire({
          icon: "info",
          title: "Login Required",
          text: "Please log in to continue.",
        });
        setIsLoading(false);
        return;  
      }
  
      const responseData = await sendPayment(authToken, data);
      setIsLoading(false);
       
      if (responseData.data.success) {

        navigate(`/thank-you?order_id=${responseData.data.order_id}&country=${responseData.data.country}`);

      } else {
        navigate(`/payment_error`);
      }
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "An error occurred while processing the payment.",
      });
    }
  };
  

  return (
    <div>
       <form onSubmit={handleSubmit(onSubmit)}>
      <div className="pt-3 max" style={{ marginLeft: '43px', marginRight: '43px' }}>
        <div style={{ marginBottom: '10px' }}>
          <input 
            type="text" 
            className="form-control" 
            id="cardnumber" 
            placeholder="Card Number" 
            {...register('cardnumber')}
          />
          <small className="text-danger">{errors.cardNumber?.message}</small>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <input 
            type="text" 
            className="form-control" 
            id="name" 
            placeholder="Card Holder Name" 
            {...register('name')} 
          />
          <small className="text-danger">{errors.firstName?.message}</small>
        </div>
        <div className="row">
          <div className="col-4">
            <select className="form-select" name="exp_month" id="expiryMonth" {...register('exp_month')}>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
              {/* other months */}
            </select>
            <small className="text-danger">{errors.expiryMonth?.message}</small>
          </div>
          <div className="col-4">
            <select className="form-select" name="exp_year" id="expiryYear" {...register('exp_year')}>
               
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              {/* other years */}
            </select>
            <small className="text-danger">{errors.expiryYear?.message}</small>
          </div>
          <div className="col-4">
            <input 
              type="text" 
              className="form-control" 
              id="cvc" 
              name="cvc"
              placeholder="CVC" 
              maxLength={4} 
              {...register('cvc')} 
            />
            <small className="text-danger">{errors.cvc?.message}</small>
          </div>
        </div>
        <div className="row mb-2 mt-3 ml-0">
          <div className="col-12 pl-1">
            <div className="text-start d-flex align-items-center">
            <input 
              className="form-check-input" 
              type="checkbox" 
              id="checkbox1" 
              {...register('checkbox1')} 
            />
            <label className="charge ml-4" htmlFor="checkbox1">
              I acknowledge that the charge will appear on my statement as "Kanoo Pays".
            </label>
            </div>
            
          </div>
          <small className="text-danger">{errors.checkbox1?.message}</small>
        </div>
        <div className="row mb-3 ml-0">
          <div className="col-12 pl-1">
            <div className="text-start d-flex align-items-center">
            <input 
              className="form-check-input" 
              type="checkbox" 
              id="checkbox2" 
              {...register('checkbox2')} 
            />
            <label className="charge ml-4" htmlFor="checkbox2">
              A backup copy of the invoice will be sent to the email address used on this application.
            </label>
            </div>
          </div>
          <small className="text-danger">{errors.checkbox2?.message}</small>
        </div>
        <div className="pb-3">
          <button type="submit" className="pay-btn w-100"  disabled={isLoading}>
           <>
           {isLoading ? (
            <span>
                <i className="fa fa-spinner fa-spin" /> Processing...
              </span>
            ) : (
              <span>
                Pay BSD${totalAmount} <i className="fa fa-solid fa-chevron-right" />
              </span>
            )}
            </>

            

          </button>
        </div>
        <a className="merchant" href="{{url('/')}}">Go back to the merchant's site</a>
        
        {/* Tab content */}
        <div className="mt-4" style={{backgroundColor: '#3b82f6', borderRadius: '8px', marginBottom: '30px', textAlign: '-webkit-center'}}>
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <div className="col-6" style={{borderRight: '1px solid #fff', borderBottom: '1px solid #fff'}}>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === 'call' ? 'active' : ''}`}
                  onClick={() => setActiveTab('call')}
                  type="button"
                  role="tab"
                  aria-selected={activeTab === 'call'}
                >
                  <i className="fa fa-solid fa-phone pt-1 pe-2" /> Call Us
                </button>
              </li>
            </div>
            <div className="col-6" style={{borderBottom: '1px solid #fff', textAlign: '-webkit-center'}}>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === 'email' ? 'active' : ''}`}
                  onClick={() => setActiveTab('email')}
                  type="button"
                  role="tab"
                  aria-selected={activeTab === 'email'}
                >
                  <i className="fa fa-solid fa-envelope pt-1 pe-2" /> Email Us
                </button>
              </li>
            </div>
          </ul>
          <div className="tab-content pb-5" id="pills-tabContent">
            {activeTab === 'call' && (
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel">
                <h6 className="Speak pt-2">Speak to a support team member.</h6>
                <h6 className="Local pt-3">Local Support: (242) 397-1600</h6>
                <h6 className="Local pt-1">Toll Free: (242) 225-1365</h6>
              </div>
            )}
            {activeTab === 'email' && (
              <div className="tab-pane fade show active" id="pills-profile" role="tabpanel">
                <h6 className="Speak pt-2">Send an email to receive Technical Support.</h6>
                <a className="text-white" href="mailto:help@kanoosupport.com">help@kanoosupport.com</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
    </div>
  );
};

export default PaymentForm;
