import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import moment from 'moment';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

function OrderTicketPrint() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(function() { 
      window.print();
  }, 1000);

    
  }, [])
  
  //const [ticketPrint, setticketPrint] = useState();
     const ticketarray = localStorage.getItem('apiResponseTicket');
      const ticketPrint =  JSON.parse(ticketarray);

      setTimeout(function() { 
        setIsLoading(false);
    }, 1000);

     
//     if (ticketarray) {
//       // Parse the data back to an object
//       const ticketdata = JSON.parse(ticketarray);
//
//       setticketPrint(ticketdata);
//
//
//       console.log(ticketPrint);
//     }
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // You can use 'auto' for instant scroll or 'smooth' for a smooth scroll animation
  });
  useEffect(() => {
    document.body.classList.add('home-bg');
    // Clean up the class when the component unmounts
    return () => {
      document.body.classList.remove('home-bg');
    };
  }, []);
  const qrCodeData = 'https://example.com';
  const renderedqr = ticketPrint.order.ticket_data.map((item, index) => {

      if (isLoading) {

          return(

               
                  <div className="col-md-6" style={{textAlign: 'center'}}>
                 <LoadingSpinner />
                 </div>

            );
      }
      return(
       <div className="col-md-6" style={{textAlign: 'center'}}>
              <p style={{marginTop: 10}}><strong>{ticketPrint.order.ticket.name}  </strong></p>
              
              <p><strong>Price :  </strong><small style={{ fontSize: '14px' }}>{ticketPrint.order.ticket.price.toFixed(2)}  </small> </p> 
              <QRCode value={item.ticket_number} />
              <p style={{marginTop: 10}}><strong>{item.ticket_number}</strong> </p>
        </div>
        )
   });
      const date = ticketPrint.order.created_at;
      const formattedDate = moment(date).format('MMMM DD, YYYY');
      const eventDate = ticketPrint.order.event.created_at;
      const formattedeventDate = moment(eventDate).format('MMMM DD, YYYY');
  return (
    <>
 <div>
  <div className="main-wrapper">
    <div className="invoiceContainer">
      <div className="section-body">
        <div className="invoice">
          <div className="invoice-print">
            <div className="row">
              <div className="col-lg-12">
                <div className="invoice-title">
                  <img src="https://tikkets.com/images/upload/628e3ff51f664.jpg" className="logoImage img img-responsive" />
                </div>
                <div style={{margin: '40px auto !important'}} className="invoice-title">
                  <h3>Order {ticketPrint.order.order_id}</h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <address>
                      <strong>Event:</strong>
                    </address>
                    <div className="media mb-3">
                      <img alt="image" className="mr-3" src={ticketPrint.order.event.imagePath + ticketPrint.order.event.image}  width={50} height={50} style={{objectFit: 'cover !important'}} />
                      <div className="media-body">
                        <div className="media-title mb-0">
                          {ticketPrint.order.event.name}
                        </div>
                        <div className="media-description text-muted">
                          {formattedeventDate}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 text-md-right">
                   {/*  <address> */}
                   {/*    <strong>Organizer:</strong><br /> */}
                   {/*     {ticketPrint.order.organization.name}<br /> */}
                   {/*   {ticketPrint.order.organization.email}<br /> */}
                   {/* {ticketPrint.order.organization.id} */}
                   {/*  </address> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <address>
                      <strong>Attendee:</strong><br />
                      {ticketPrint.order.customer.name}<br />
                      {ticketPrint.order.customer.email}<br />
                    </address>
                  </div>
                  <div className="col-md-6 text-md-right">
                    <address>
                      <strong>Order Date:</strong><br />
                       { formattedDate}<br /><br />
                    </address>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="section-title "><h4>QR Code(s)</h4></div>
                <hr />
                <div className="row">

                    {renderedqr}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</>
  )
}
export default OrderTicketPrint