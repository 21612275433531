import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { fetchAllCategories } from "../../services/allcategoryService";
import { searchEvents } from "../../services/searchEventsService";
import Swal from "sweetalert2";
import { useOrders } from "../../hooks/SettingContext";
import { Helmet } from "react-helmet";
import NewLogin from "../../auth/NewLogin";
import NewRegister from "../../auth/NewRegister";
import { ProfileContext } from '../../hooks/ProfileContext';
function HeaderComponent() {
  const [showPopup, setShowPopup] = useState(false);
  // const handleShowPopup = () => setShowPopup(true);
  // const handleClosePopup = () => setShowPopup(false);
  const [RegistershowPopup, setRegistershowPopup] = useState(false);

  // const handleRegisterShowPopup = () => setRegistershowPopup(true);
  // const handleRegisterClosePopup = () => setRegistershowPopup(false);
  const navigate = useNavigate();
  const isLoadingRef = useRef(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [allData, setallData] = useState([]);
  const [openModel, setopenModel] = useState(false);
  const locationStorage = localStorage.getItem("authToken");
  const [localStorageValue, setLocalStorageValue] = useState(false);
  const [LocalStorageUserId, setLocalStorageUserId] = useState([]);
  const [LocalStorageUserName, setLocalStorageUserName] = useState([]);
  const locationStorageUserID = localStorage.getItem("userID");
  const locationStorageUserNAME = localStorage.getItem("userName");
  const { imagePath } = useContext(ProfileContext);
  const location = useLocation();

  // const handleShowPopup = () => {
  //   setopenModel(true);
  //   setShowPopup(true);
  // };
  // const handleClosePopup = () => {
  //   setopenModel(false);
  //   setShowPopup(false);
  // };

  // const handleRegisterShowPopup = () => {
  //   setopenModel(true);
  //   setRegistershowPopup(true);
  // };
  // const handleRegisterClosePopup = () => {
  //   setopenModel(false);
  //   setRegistershowPopup(false);
  // };

  // const handleSwitchToRegister = () => {
  //   setShowPopup(false);
  //   setRegistershowPopup(true);
  // };

  // const handleSwitchToLogin = () => {
  //   setRegistershowPopup(false);
  //   setShowPopup(true);
  // };
  useEffect(() => {
    const parsedTicketData = locationStorageUserID;
    setLocalStorageUserId(parsedTicketData);
  }, [locationStorageUserID]);
  useEffect(() => {
    const parsedTicketData = locationStorageUserNAME;
    setLocalStorageUserName(parsedTicketData);
  }, [locationStorageUserNAME]);
  const handleLoginPopup = () => {
    setShowPopup(true);
  };
  useEffect(() => {
    setLocalStorageValue(locationStorage);
  }, [locationStorage]);

  const role = localStorage.getItem("role");
  const [roleValue, setRoleValue] = useState(false);

  useEffect(() => {
    setRoleValue(role);
  }, [role]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [showSearch, setShowSearch] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false); // State variable for sidebar menu
  const categoriesFetchedRef = useRef(false);
  const [pageLoading, setPageLoading] = useState(false);
 
  const { ordersCount, updateOrdersCount } = useOrders();

  const getActiveClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };
  const fetchData = async () => {
    try {
      if (!categoriesFetchedRef.current) {
        const categories = await fetchAllCategories();
        setallData(categories);
        categoriesFetchedRef.current = true;
      }
    } catch (error) {
      console.error("Error fetching categories data:", error);
    }
  };

  const handleCart = () => {
    // Retrieve loginUser from localStorage
    const loginUser = localStorage.getItem("userID");
  
    // Retrieve CartData from localStorage
    const cartData = JSON.parse(localStorage.getItem("CartData")) || {};
  
    if (loginUser && cartData[loginUser] && cartData[loginUser].length > 0) {
      // If loginUser exists and there are items in CartData for loginUser
      navigate("/cart"); // Navigate to the cart page
    } else {
      // Show error message if cart is empty
      Swal.fire({
        icon: "error",
        title: "There are no items in your cart right now.",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };
  
  // const handleSwitchToLoginModel = () => {
  //   handleShowPopup();
  // };
  useEffect(() => {
    if (!isLoadingRef.current && !pageLoading) {
      isLoadingRef.current = true;
      fetchData();
    }
  }, []);
  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };
  const toggleHeader = () => {
    setShowHeader(!showHeader);
  };
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userID");
    localStorage.removeItem("userName");
    localStorage.removeItem("role");
    window.location.href = "/";
    updateOrdersCount(0);
  };
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleChange = (event) => {
    setQuery(event.target.value);
  };
  const handleSubmit = async (event, query, navigate) => {
    event.preventDefault();
    try {
      const searchResults = await searchEvents(query);
      navigate("/general-search", { state: { results: searchResults, query: query } });
    } catch (error) {
      console.error(error.message);
    }
  };
  const headerStyle = {
    backgroundColor: '#0E46A3',
    color: 'white',
    padding: '10px 0'
  };
  return (
    <>
      <Helmet>
        <link href="/css/bootstrap.min.css" rel="stylesheet" />
        <link href="/css/header.css" rel="stylesheet" />
      </Helmet>
      {/* <header
        className={`header-1 header-light no-filter style-add bg-silver2 ${showHeader ? "show" : ""}`}> */}

        <header className={`header-1  header_blue ${showHeader ? "show" : ""}`}
           style={headerStyle}
           >

        <div className="container">
          <div className="header-mobile">
            <div className="navbar-handler">
              <button className="navbar-trigger" onClick={toggleSidebar}>
                <img src="/images/hamburger-black.png" alt="hamburger-black" />
              </button>
            </div>
            <Link to="/">
              <img src="/images/logo-white.png" alt="logo-black" />
            </Link>
            <button className="search-toggle d-none" onClick={toggleSearch}>
              <img className="d-none" src="/images/search-icon.png" alt="search-icon" />
            </button>
            <div className="menu-item d-flex">
              <i onClick={handleCart} className="fa " style={{ fontSize: "16px", color: "white!important", cursor: "pointer", marginLeft: '0px !important' }}>&#xf07a;</i>

              <span className="badge badge-warning" id="lblCartCount" style={{ color: "black" }}>

                {ordersCount ? ordersCount : 0}
              </span>
            </div>
            {showSearch && (
              <div className={`search-form ${showSearch ? "show" : ""}`}>
                <form
                  onSubmit={(event) => handleSubmit(event, query, navigate)}
                  className="search-form">
                  <input
                    type="hidden"
                    name="_token"
                    defaultValue="UVCA61sTzWZJSeI1Y4SzxwfEfur2kyvPiHKxGUrt"
                  />
                  <div className="search-bar-container">
                    <input
                      className="search-bar"
                      type="text"
                      placeholder="Search"
                      value={query}
                      onChange={handleChange}
                    />
                    <button type="submit" className="search-button">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="search">
                        <g data-name="Layer 2">
                          <path
                            d="m20.71 19.29-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"
                            data-name="search"></path>
                        </g>
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
          {isMobile && showSidebar ? (
          <div className={`custom-sidebar px-3 ${showSidebar ? "custom-open-filter-mobile" : "custom-d-none"}`} id="mySidebar">
            <Link className="custom-closebtn" onClick={toggleSidebar}> 
            {/* <i className="fas fa-chevron-left"></i> */}
            </Link>
          
            {localStorageValue && (
              <div className="custom-user-profile px-0 lg:d-none">
                <img
                  src={imagePath ? imagePath : '/images/notlogin.png'}
                  className="custom-user-image"
                  alt="User Image"
                  id="LogoButtonImge"
                />
                <div>
                  <h4><b>{LocalStorageUserName}</b></h4>
                  <p className="text-white">USER ID: (#{LocalStorageUserId})</p>
                </div>
              </div>
            )}
            <Link to="/" className={`custom-menu-item rounded-4 ${getActiveClass('/')}`}><i className="fas fa-home custom-menu-icon"></i> Home</Link>
            <Link to="/all-blogs" className={`custom-menu-item rounded-4 ${getActiveClass('/all-blogs')}`}><i className="fas fa-th-large custom-menu-icon"></i> Blog</Link>
            <Link to="/contact" className={`custom-menu-item rounded-4 ${getActiveClass('/contact')}`}><i className="fas fa-phone custom-menu-icon"></i> Contact Us</Link>
            
            {localStorageValue ? (
              <>
                <Link to="/user/my-tickets" className={`custom-menu-item rounded-4 ${getActiveClass('/user/my-tickets')}`}><i className="fas fa-ticket-alt custom-menu-icon"></i> My Tickets</Link>
              </>
            ) : (
              <>
                <Link to='/user/login' className={`custom-menu-item rounded-4 ${getActiveClass('/user/login')}`}><i className="fas fa-ticket-alt custom-menu-icon"></i> My Tickets </Link>
              </>
            )}
            <Link to="/faq" className={`custom-menu-item rounded-4 ${getActiveClass('/faq')}`}><i className="fas fa-question-circle custom-menu-icon"></i> FAQs</Link>
            {localStorageValue ? (
              <>

                <div className="menu-item">
                  <Link className={`custom-menu-item rounded-4 ${getActiveClass('/user/profile')}`} to="/user/profile"><i className="fas fa-user custom-menu-icon"></i> Profile</Link>
                </div>
            
                <div className="menu-item">
                  <Link onClick={handleLogout} className={`custom-menu-item rounded-4`}><i className="fas fa-sign-out-alt custom-menu-icon"></i> Logout</Link>
                </div>
              </>
            ) : (
              <>
                <div className="menu-item">
                  <Link className='custom-menu-item' to='/user/login'>
                  <i className="fas fa-sign-in-alt custom-menu-icon"></i> Login
                  </Link>
                </div>
                <div className="menu-item">
                  <Link
                    className='custom-menu-item'
                    to='/user/register'>
                   <i className="fas fa-user-plus custom-menu-icon"></i>Register
                  </Link>
                </div>

              </>
            )}

          </div>
            ) : null
          } 
          <div className="header-row">
            <div className="logo">
              <Link to="/">
                <img src="/images/logo-white.png" alt="logo" />
              </Link>
            </div>
            <div className="search-form" id="search-form-id">
              <form
                onSubmit={(event) => handleSubmit(event, query, navigate)}
                className="search-form d-none">
                <input
                  type="hidden"
                  name="_token"
                  defaultValue="UVCA61sTzWZJSeI1Y4SzxwfEfur2kyvPiHKxGUrt"
                />
                <div className="search-bar-container">
                  <input
                    className="search-bar search-bar-black"
                    type="text"
                    placeholder="Search"
                    value={query}
                    onChange={handleChange}
                  />
                  <button type="submit" className="search-button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="searchblack">
                      <g data-name="Layer 2">
                        <path
                          d="m20.71 19.29-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"
                          data-name="search"></path>
                      </g>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
            <div className="navbar-custom">
              {/* <div className="menu-item" id="black-link">
              <Link to="/all-events"> Events </Link>
            </div> */}
              <div className="menu-item ">
                <Link to="/"> Home </Link>
              </div>
              <div className="menu-item" id="black-link">
                <Link to="/all-blogs"> Blog </Link>
              </div>
              <div className="menu-item" id="black-link">
                <Link to="/contact"> Contact Us </Link>
              </div>
              <div className="menu-item" id="black-link">
                {roleValue === "user" ? (
                  <>
                    <Link to="/user/my-tickets"> My Tickets </Link>
                  </>
                ) : (
                  <>
                    {/* <Link onClick={handleShowPopup}> My Tickets </Link> */}
                    <Link to='/user/login'> My Tickets </Link>
                  </>
                )}
              </div>
              <div className="menu-item " id="black-link">
                <Link to="/faq"> FAQs </Link>
              </div>
              <div className="menu-item">
                <a>

              
                <i
                  onClick={handleCart}
                  className="fa"
                  style={{ fontSize: "16px", color: "white", cursor: "pointer"}}>
                  &#xf07a;
                </i>

                <span className="badge badge-warning" id="lblCartCount" style={{ color: "black" }}>
                  {ordersCount ? ordersCount : 0}
                </span>
                </a>
              </div>

              {!localStorageValue && (
                <>
                 <div className="menu-item " id="black-link">
                <Link to="/user/login"> Login </Link>
              </div>

              <div className="menu-item " id="black-link">
                <Link to="/user/register"> Register </Link>
              </div>
              </>
             
              )}


 
              {/* <div className="menu-item mobileOnly">
              <Link to="/user/login">User Sign In</Link>
            </div>
            <div className="menu-item mobileOnly">
              <Link to="/user/register">User Sign Up</Link>
            </div>
            <div className="menu-item mobileOnly">
              <Link to="/user/org-login">Organizer Sign In</Link>
            </div>
            <div className="menu-item mobileOnly">
              <Link to="/user/org-register">Organizer Sign Up</Link>
            </div> */}
              <div className="menu-item mobileOnly">
                <Link onClick={handleLogout}>Logout</Link>
              </div>
            </div>
            {localStorageValue && (
                   <div className="menu-item">
                   <div className="user-profile">
                     <div className="dropdown">
                       <button
                         className="btn btn-secondary dropdown-toggle"
                         type="button"
                         id="dropdownMenuButton"
                         data-toggle="dropdown"
                         aria-haspopup="true"
                         aria-expanded="false">
                         <img
                           src={imagePath ? imagePath : '/images/notlogin.png'}
                           style={{ width: 38, height: 35, objectFit: "cover" }}
                           alt={imagePath ? "Profile" : "notlogin"}
                         />
                       </button>
                       <span className="ml-2" style={{ display: "inline-flex",verticalAlign: "middle" }}>
                        {LocalStorageUserName}
                          <br /> 
                          USER ID: (#{LocalStorageUserId})
                        </span>
                       <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                         {roleValue === "user" && (
                           <>
                             <Link className="dropdown-item" to="#">
                               User ID <br />#{LocalStorageUserId}
                             </Link>
                             <Link className="dropdown-item" to="/user/profile">
                               Profile
                             </Link>
                             <Link className="dropdown-item" to="/user/my-tickets">
                               My Tickets
                             </Link>
                             <Link className="dropdown-item" onClick={handleLogout}>
                               Logout
                             </Link>
                           </>
                         )}
     
                         {roleValue === "organizer" && (
                           <>
                             <Link className="dropdown-item" to="/dashboard">
                               Dashboard
                             </Link>
                             <Link className="dropdown-item" onClick={handleLogout}>
                               Logout
                             </Link>
                           </>
                         )}
                         {role === null && (
                           <>
                             <Link className="dropdown-item" to='/user/login'>
                               Login
                             </Link>
                             <Link
                               className="dropdown-item"
                               to='/user/register'>
                               Register
                             </Link>
                             {/* <Link className="dropdown-item" onClick={handleShowPopup}>
                               User Sign In
                             </Link>
                             <Link
                               className="dropdown-item"
                               onClick={handleRegisterShowPopup}
                               handleLogin={handleLoginPopup}>
                               User Sign Up
                             </Link> */}
                             {/* <Link className="dropdown-item" to="/user/org-login">
                             Organizer Sign In
                           </Link>
                           <Link className="dropdown-item" to="/user/org-register">
                             Organizer Sign Up
                           </Link> */}
                             {/* <Link className="dropdown-item" to="/user/login">
                             Login
                           </Link> */}
     
                             {openModel ? (
                               <>
                                 {/* <NewRegister
                                   show={RegistershowPopup}
                                   handleClose={handleRegisterClosePopup}
                                   handleSwitchToLogin={handleSwitchToLogin}
                                 /> */}
                               </>
                             ) : null}
                             {/* <NewLogin
                               show={showPopup}
                               handleClose={handleClosePopup}
                               handleSwitchToRegister={handleSwitchToRegister}
                               handleSwitchToLoginModel={handleSwitchToLoginModel}
                             /> */}
                           </>
                         )}
                       </div>
                       
     
                     </div>
                   </div>
                 </div>

              )}
       
             
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderComponent;
