import { API_ENDPOINTS } from './api';

export const checkOutCard = async (authToken, formData) => {
    try {
        const response = await fetch(`${API_ENDPOINTS.CHECKOUTCARDPAYMENT}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(formData),
        });

        // Convert the JSON response to an array
      const responseData = await response.json();
      console.log(responseData,'--------------');
      // Now you have the JSON data as an array
      return responseData;

       
      //   if (!response.ok) {
      //     // Handle non-successful responses here
      //     throw new Error(`HTTP error! Status: ${response.status}`);
      // }

      
  
         
   
    } catch (error) {
        throw new Error("Network error occurred.");
    }
};
// export const checkCouponCode = async (authToken, couponCode) => {
//   try {
//     const response = await fetch(`${API_ENDPOINTS.COUPON_CART}`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${authToken}`,
//       },
//       body: JSON.stringify({ coupon_code: couponCode }),
//     });
     
   
//     const data = await response.json();

//     return data.data;
//   } catch (error) {
//       console.log(error.msg,'error1');
//     throw new Error(error.message);
//   }
// };
export const checkCouponCode = async (authToken, couponCode) => {
  const response = await fetch(`${API_ENDPOINTS.COUPON_CART}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ coupon_code: couponCode }),
  });

  const data = await response.json();

  return data;
};
export const storeDumyOrder = async (authToken, updatedFormData) => {
 
   
  const response = await fetch(`${API_ENDPOINTS.dummy_cart}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify(updatedFormData),
  });

  const data = await response.json();

  return data;
};


export const checkEvent_Currency = async (eventIds, tiketsIds) => {
  const data = await fetch(`${API_ENDPOINTS.checkEventCurrency+eventIds}` + '&ticketIds=' + tiketsIds , {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const response = await data.json();
 
  return response;
};
