import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import moment from 'moment-timezone';

const PaymentForm = () => {
  const [timezone, setTimezone] = useState('Europe/Berlin');
  const [txndatetime, setTxndatetime] = useState('');
  const [environment, setEnvironment] = useState('https://test.ipg-online.com/connect/gateway/processing');
  const [sharedSecret, setSharedSecret] = useState('tqnD.,39Gq');
  const [hashExtended, setHashExtended] = useState('');
  const [formData, setFormData] = useState({
    hash_algorithm: 'HMACSHA256',
    checkoutoption: 'combinedpage',
    language: 'en_US',
    oid: '#123456',
    storename: '811801256',
    full_bypass: 'false',
    dccSkipOffer: 'false',
    paymentMethod: '',
    txntype: 'sale',
    chargetotal: '13.00',
    authenticateTransaction: 'false',
    currency: '840',
    referencedSchemeTransactionId: '',
    unscheduledCredentialOnFileType: '',
    responseFailURL: 'https://tikkets.com/paymentIntegrationFail',
    responseSuccessURL: 'https://tikkets.com/paymentIntegrationSuccess',
    assignToken: 'false',
    hosteddataid: ''
  });

  useEffect(() => {
    updateDatetime();
  }, [timezone]);

  const updateDatetime = () => {
    setTxndatetime(moment().tz(timezone).format('YYYY:MM:DD-HH:mm:ss'));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const paymentParameters = {
      ...formData,
      txndatetime,
    };

    const messageSignatureContent = [];
    const ignoreSignatureParameters = ['hashExtended'];

    Object.keys(paymentParameters)
      .filter(key => !ignoreSignatureParameters.includes(key))
      .sort()
      .forEach(key => {
        messageSignatureContent.push(paymentParameters[key]);
      });

    const messageSignature = CryptoJS.HmacSHA256(messageSignatureContent.join('|'), sharedSecret);
    const messageSignatureBase64 = CryptoJS.enc.Base64.stringify(messageSignature);
    setHashExtended(messageSignatureBase64);

    // Create form element dynamically to submit
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = environment;
    Object.keys(paymentParameters).forEach(key => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = paymentParameters[key];
      form.appendChild(input);
    });

    const hashInput = document.createElement('input');
    hashInput.type = 'hidden';
    hashInput.name = 'hashExtended';
    hashInput.value = messageSignatureBase64;
    form.appendChild(hashInput);

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <div>
      <fieldset>
        <legend>IPG Connect Request Prerequisites</legend>
        <p>The following values are <u>not sent</u> to the server but rather necessary to generate the according request hash. The request hash calculation does not include parameters with empty values.</p>
        <p>
          <label htmlFor="environment">Test Environment:</label>
          <select name="environment" value={environment} onChange={(e) => setEnvironment(e.target.value)}>
            <option value="https://test.ipg-online.com/connect/gateway/processing">CI / Test</option>
            <option value="https://www.ipg-online.com/connect/gateway/processing">Prod</option>
          </select>
        </p>
        <p>
          <label htmlFor="sharedsecret">Shared Secret:</label>
          <input type="text" name="sharedsecret" value={sharedSecret} onChange={(e) => setSharedSecret(e.target.value)} />
        </p>
      </fieldset>
      <form id="paymentForm" onSubmit={handleFormSubmit}>
        <input type="hidden" name="hash_algorithm" value="HMACSHA256" />
        <input type="hidden" name="checkoutoption" value="combinedpage" />
        <input type="hidden" name="language" value="en_US" />
        <input type="hidden" name="oid" value="#123456" />
        <fieldset>
          <legend>IPG Connect Request Details</legend>
          <p>
            <label htmlFor="storename">Store ID:</label>
            <input type="text" name="storename" value={formData.storename} onChange={handleInputChange} />
          </p>
          <p>
            <label htmlFor="full_bypass">Full ByPass:</label>
            <select name="full_bypass" value={formData.full_bypass} onChange={handleInputChange}>
              <option value="false">false</option>
              <option value="true">true</option>
            </select>
          </p>
          <p>
            <label htmlFor="dccSkipOffer">Skip DCC Offer:</label>
            <select name="dccSkipOffer" value={formData.dccSkipOffer} onChange={handleInputChange}>
              <option value="false">false</option>
              <option value="true">true</option>
            </select>
          </p>
          <p>
            <label htmlFor="paymentMethod">Payment Method:</label>
            <select name="paymentMethod" value={formData.paymentMethod} onChange={handleInputChange}>
              <option value="">-</option>
              <option value="V">Visa</option>
              <option value="M">Mastercard</option>
              <option value="postfinance">Postfinance eFinance</option>
              <option value="postfinance_card">Postfinance Card</option>
            </select>
          </p>
          <p>
            <label htmlFor="timezone">Timezone:</label>
            <select name="timezone" value={timezone} onChange={(e) => setTimezone(e.target.value)}>
              <option value="Europe/Berlin">Europe/Berlin</option>
              <option value="Asia/Dubai">Asia/Dubai</option>
            </select>
          </p>
          <p>
            <label htmlFor="txndatetime">Transaction Datetime:</label>
            <input type="text" name="txndatetime" value={txndatetime} readOnly />
          </p>
          <p>
            <label htmlFor="txntype">Transaction Type:</label>
            <select name="txntype" value={formData.txntype} onChange={handleInputChange}>
              <option value="sale">Sale</option>
            </select>
          </p>
          <p>
            <label htmlFor="chargetotal">Transaction Amount:</label>
            <input type="text" name="chargetotal" value={formData.chargetotal} onChange={handleInputChange} />
          </p>
          <p>
            <label htmlFor="authenticateTransaction">Authenticate Transaction:</label>
            <select name="authenticateTransaction" value={formData.authenticateTransaction} onChange={handleInputChange}>
              <option value="false">false</option>
              <option value="true">true</option>
            </select>
          </p>
          <p>
            <label htmlFor="currency">Currency (see <a href="https://de.wikipedia.org/wiki/ISO_4217">ISO4217</a>):</label>
            <select name="currency" value={formData.currency} onChange={handleInputChange}>
              <option value="840">USD (840)</option>
              <option value="978">EUR (978)</option>
              <option value="985">PLN (985)</option>
              <option value="784">AED (784)</option>
              <option value="048">BHD (048)</option>
              <option value="826">GBP (826)</option>
              <option value="756">CHF (756)</option>
            </select>
          </p>
          <p>
            <label htmlFor="referencedSchemeTransactionId">Referenced Scheme Transaction Id:</label>
            <input type="text" name="referencedSchemeTransactionId" value={formData.referencedSchemeTransactionId} onChange={handleInputChange} />
          </p>
          <p>
            <label htmlFor="unscheduledCredentialOnFileType">Unscheduled Credential On FileType:</label>
            <select name="unscheduledCredentialOnFileType" value={formData.unscheduledCredentialOnFileType} onChange={handleInputChange}>
              <option value=""></option>
              <option value="FIRST">FIRST</option>
              <option value="CARDHOLDER_INITIATED">CARDHOLDER_INITIATED</option>
              <option value="MERCHANT_INITIATED">MERCHANT_INITIATED</option>
            </select>
          </p>
          <p>
            <label htmlFor="responseFailURL">Failure URL:</label>
            <input type="text" name="responseFailURL" size="60" value={formData.responseFailURL} onChange={handleInputChange} />
          </p>
          <p>
            <label htmlFor="responseSuccessURL">Success URL:</label>
            <input type="text" name="responseSuccessURL" size="60" value={formData.responseSuccessURL} onChange={handleInputChange} />
          </p>
          <fieldset>
            <legend>Tokenization</legend>
            <p>
              <label htmlFor="assignToken">Assign Token:</label>
              <select name="assignToken" value={formData.assignToken} onChange={handleInputChange}>
                <option value="false">false</option>
                <option value="true">true</option>
              </select>
            </p>
            <p>
              <label htmlFor="hosteddataid">Hosted Data Id:</label>
              <input type="text" name="hosteddataid" size="60" value={formData.hosteddataid} onChange={handleInputChange} />
            </p>
          </fieldset>
          <p>
            <input type="submit" id="submit" value="Submit" />
          </p>
        </fieldset>
      </form>
    </div>
  );
};

export default PaymentForm;
